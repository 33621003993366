import React, { createContext, useState, useEffect, useContext } from 'react';

const OnlineStatusContext = createContext();

export const OnlineStatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Update online status based on changes to network connectivity
  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={isOnline}>
      {!isOnline && (
        <div style={{
          padding: '10px',
          backgroundColor: '#ffcccc',
          color: '#a94442',
          textAlign: 'center',
          fontSize: '16px'
        }}>
          You are offline. Some features may be unavailable.
        </div>
      )}
      {children}
    </OnlineStatusContext.Provider>
  );
};

// Hook to access online status in other components
export const useOnlineStatus = () => useContext(OnlineStatusContext);
