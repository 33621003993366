const config = {
  api_base_url: process.env.REACT_APP_API_BASE_URL || '',
  aws_user_pool: process.env.REACT_APP_AWS_USER_POOL || 'us-east-2_24HmHft9G',
  clientId: process.env.REACT_APP_CLIENT_ID || '6vltklt3au1giaah4248h1jeus',
  authenticationDomain: process.env.REACT_APP_AUTHENTICATION_DOMAIN || 'identity.tikitribe.net',
  redirectLoginUri: process.env.REACT_APP_LOGIN_REDIRECT_URI || 'https://tikitribe.net/home',
  redirectLogoutUri: process.env.REACT_APP_LOGOUT_REDIRECT_URI || 'https://tikitribe.net/',
  aws_lambda_user_data_get: process.env.REACT_APP_USER_DATA_GET || '',
  stripe_public: process.env.REACT_APP_STRIPE_PUBLIC || '',
};

export default config;
