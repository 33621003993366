import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const UpgradeModalFiles = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Join the Island Adventure!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Only Islanders and Wayfinders have the ability to upload files.
          Upgrade your journey to unlock this privilege and show your best to the Tribe!
          Mahalo for supporting a community that's ad-free and fiercely private.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => window.location.href = '/subscriptions'}>
          Join the Adventure
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Maybe Later
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpgradeModalFiles;
