import { createContext, useContext, useCallback, useMemo } from 'react';
import ApiClient from '../ApiClient';
import { useFlash } from './FlashProvider';
import { useAuth } from './AuthContext';

const ApiContext = createContext();

export default function ApiProvider({ children }) {
  const flash = useFlash();
  // const { token, refreshToken, logout } = useAuth();  // Import token, refreshToken, and logout from useAuth
  const { token, logout } = useAuth();  // Import token, refreshToken, and logout from useAuth

  const onError = useCallback(() => {
    flash('An unexpected error has occurred. Please try again later.', 'danger');
  }, [flash]);

  // // Pass token, refreshToken, and logout to ApiClient
  // const api = useMemo(() => new ApiClient({
  //   token,
  //   refreshToken,
  //   logout,
  //   onError,
  // }), [token, refreshToken, logout, onError]);
  // Pass token, refreshToken, and logout to ApiClient
  const api = useMemo(() => new ApiClient({
    token,
    logout,
    onError,
  }), [token, logout, onError]);

  return (
    <ApiContext.Provider value={api}>
      {children}
    </ApiContext.Provider>
  );
}

export function useApi() {
  return useContext(ApiContext);
}
