import React, { useState } from 'react';
import {useApi} from "../contexts/ApiProvider";
import {useAuth} from "../contexts/AuthContext";


const StripeCustomerPortal = ({ customerId }) => {
  const api = useApi();
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);

  // Function to handle redirecting to the customer portal
  const handleOpenPortal = async () => {
    setLoading(true);
    try {
      const url = '/tt_stripe_customer_portal_session';
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const response = await api.get(url, null, { headers }, token);
      console.log(response);
      const data = await response.body;
      console.log(data);
      if (data.url) {
        // Redirect to the Stripe Customer Portal URL
        window.location.href = data.url;
      } else {
        alert('Failed to create a portal session. Please try again.');
      }
    } catch (error) {
      console.error('Error redirecting to the customer portal:', error);
      alert('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button
        onClick={handleOpenPortal}
        disabled={loading}
        style={{
          padding: '10px 20px',
          backgroundColor: '#e67e22',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        {loading ? 'Loading...' : 'Manage Your Subscription'}
      </button>
    </div>
  );
};

export default StripeCustomerPortal;
