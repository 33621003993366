import React, {useState, useEffect, useRef} from 'react';
import TimeAgo from './TimeAgo';
import {useAuth} from "../contexts/AuthContext";
import Comment from './Comment';
import {Image, ButtonGroup, Button, Spinner, Stack, Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import InputFieldComments from './InputFieldComments';
import pencilIcon from '../assets/ps_edit.png';
import deleteIcon from '../assets/ps_delete.png';
import commentIcon from '../assets/ps_comment.png';
import plusIcon from '../assets/ps_plus.png';
import shakaIcon from '../assets/tt_shaka.png';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import FileUploadAndComment from './FileUploadAndComment';
import {useApi} from "../contexts/ApiProvider";
import UpgradeModal from '../components/UpgradeModal';
import {useUser} from "../contexts/UserProvider";
import PostContent from '../components/PostContent';


function identifyMimeType(url) {
  const extension = url.split('.').pop().split('?')[0].toLowerCase();
  const mimeTypes = {
    'mp4': 'video',
    'm4v': 'video',
    'mkv': 'video',
    'mov': 'video',
    'avi': 'video',
    'flv': 'video',
    'wmv': 'video',
    'jpg': 'image',
    'jpeg': 'image',
    'png': 'image',
    'gif': 'image',
    'bmp': 'image',
    'svg': 'image',
    'webp': 'image'
  };

  return extension in mimeTypes ? mimeTypes[extension] : `extension ${extension} not in known file types`;
}

export default function Post({post, updatePostInParent, removePostFromParent}) {
  const api = useApi();
  const {token, username} = useAuth();
  const {user} = useUser();
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(post.Content);
  const [fullMediaObject, setFullMediaObject] = useState(null);
  const [loadingMedia] = useState(false);
  const [preloadedMedia, setPreloadedMedia] = useState(null);
  const [thumbnailVisible, setThumbnailVisible] = useState(true);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const textareaRef = useRef(null); // Reference for textarea

  // New state variables for likes and ripple effect
  const [likes, setLikes] = useState(post.Likes || 0);
  const [isShakaClicked, setIsShakaClicked] = useState(false);

  // Check if user data is available and if user is a Beachcomber
  const isBeachcomber = user?.data?.subscription_level === 'beachcomber';
  const mediaType = post.MediaObject ? identifyMimeType(post.MediaObject) : null;

  const toggleCommentForm = () => {
    if (isBeachcomber) {
      setShowUpgradeModal(true); // Show the upgrade modal if the user is a Beachcomber
    } else {
      setShowCommentForm(true);
    }
  };
  const toggleCommentFormCancel = () => setShowCommentForm(prev => !prev);

  const toggleInputType = () => {
    if (isBeachcomber) {
      setShowUpgradeModal(true); // Show the upgrade modal if the user is a Beachcomber
    } else {
      setShowFileInput(!showFileInput); // Toggle the file input for other users
    }
  };

  const handleMediaClick = () => {
    if (user?.data?.subscription_level === 'beachcomber') {
      setShowUpgradeModal(true); // Show modal instead of loading media
    } else if (preloadedMedia) {
      setFullMediaObject(preloadedMedia);
      setThumbnailVisible(false);
    }
  };

  const handleCommentSubmit = async () => {
    const text = commentText.trim();
    if (!text) {
      return;
    }
    try {
      const commentData = {
        ParentPostID: post.PostID,
        text: text
      };

      const response = await api.post('/tt_comments_post', commentData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }, token);

      if (response.ok) {
        const newComment = response.body;

        post.Comments = [...post.Comments, newComment];
        // Propagate the updated post back to the parent
        updatePostInParent(post);

        setCommentText('');
        setShowCommentForm(false);
        alert('Comment submitted successfully!');
      } else {
        alert('Failed to submit comment. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting comment:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleEditSave = async () => {
    try {
      const postData = {
        PostID: post.PostID,
        Content: editText
      };

      const response = await api.post('/tt_post_edit', postData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }, token);

      if (response.ok) {
        const updatedPost = response.body;
        setIsEditing(false);
        updatePostInParent(updatedPost);
        alert('Post updated successfully!');
      } else {
        alert('Failed to update post. Please try again.');
      }
    } catch (error) {
      console.error('Error updating post:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleEditCancel = () => {
    setIsEditing(false);
    setEditText(post.Content);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this post?")) {
      try {
        const postData = {
          PostID: post.PostID
        };

        const response = await api.post('/tt_post_delete', postData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }, token);

        if (response.ok) {
          removePostFromParent(post.PostID);
          alert('Post deleted successfully!');
        } else {
          alert('Failed to delete post. Please try again.');
        }
      } catch (error) {
        console.error('Error deleting post:', error);
        alert('An error occurred. Please try again.');
      }
    }
  };
  // New function to handle the Shaka button click
  const handleShakaClick = async () => {
    // Trigger the ripple effect
    setIsShakaClicked(true);

    // Increase the likes locally
    setLikes((prevLikes) => prevLikes + 1);

    // Make the API call to increase the like count
    try {
      const response = await api.post(
        '/tt_like_increase',
        { PostID: post.PostID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
        token
      );

      if (!response.ok) {
        console.error('Failed to increase like count');
      }
    } catch (error) {
      console.error('Error increasing like count:', error);
    }

    // Reset the ripple effect after animation completes
    setTimeout(() => setIsShakaClicked(false), 600); // Adjust timing as per your CSS animation
  };
  useEffect(() => {
    const preloadMedia = async () => {
      if (post.OriginalMediaUpload && user?.data?.subscription_level !== 'beachcomber') {
        try {
          const response = await api.post('/tt_s3_url_large_file_post', {PostID: post.PostID}, {  // This should be a GET sending the Post ID
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });

          if (response.ok) {
            const presignedUrl = response.body.presignedUrl;
            setPreloadedMedia(presignedUrl);
          } else {
            console.error('Failed to preload media. Please try again.');
          }
        } catch (error) {
          console.error('Error preloading media:', error);
        }
      }
    };

    preloadMedia();
  }, [post.OriginalMediaUpload, post.PostID, api, token, user?.data?.subscription_level]);

  const handleCloseModal = () => setShowUpgradeModal(false);

  const handleTextareaChange = (e) => {
    setCommentText(e.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set height based on scroll height
    }
  };

  if (!user || !user.data) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{
      // marginBottom: '10px',
      border: '1px solid #e2e2e2',
      borderRadius: '8px',
      backgroundColor: '#ffffff', // Neutral background for posts
      padding: '15px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
    }}>
      {/* Post header with avatar and username */}
      <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
        <Image
          src={post.avatar_url || '/tt_icon.png'}
          onError={(e) => {
            e.target.src = '/tt_icon.png';
          }}
          alt={post.UserID}
          roundedCircle
          style={{
            width: '40px',
            height: '40px',
            objectFit: 'cover',
            marginRight: '12px',
            border: '2px solid #e2e2e2'
          }}
        />
        <div>
          {username !== post.UserID ? (
            <Link to={'/member/' + post.UserID} style={{fontWeight: 'bold', color: '#007bff'}}>
              {post.UserID}
            </Link>
          ) : (
            <Link to={'/profile/'} style={{fontWeight: 'bold', color: '#007bff'}}>
              {post.UserID}
            </Link>
          )}
          &nbsp;&mdash;&nbsp;
          <TimeAgo isoDate={post.Timestamp}/>
          {post.Edited && <span style={{fontStyle: 'italic', color: '#6c757d'}}> (edited)</span>}
        </div>
      </div>

      {/* Media display section */}
      {post.MediaObject && (
        <div style={{marginBottom: '12px'}}>
          {thumbnailVisible && mediaType === 'image' && (
            <img
              src={post.MediaObject}
              alt="Media"
              style={{
                width: '100%',
                height: 'auto',
                cursor: 'pointer',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
              }}
              onClick={handleMediaClick}
            />
          )}
          {loadingMedia && <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>}

          {fullMediaObject && post.MediaType === 'video' && (
            <video width="100%" controls style={{borderRadius: '8px'}}>
              <source src={fullMediaObject} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          )}

          {fullMediaObject && post.MediaType === 'image' && (
            <img
              src={fullMediaObject}
              alt="Full Media"
              style={{width: '100%', height: 'auto', borderRadius: '8px'}}
            />
          )}
        </div>
      )}

      {/* Post content and actions */}
      {isEditing ? (
        <div style={{marginTop: '8px'}}>
          <textarea
            value={editText}
            onChange={e => setEditText(e.target.value)}
            style={{
              width: '100%',
              height: '3em',
              padding: '5px 10px',
              borderRadius: '8px',
              border: '1px solid #ced4da'
            }}
          />
          <Button variant="outline-primary" onClick={handleEditSave} style={{marginRight: '5px'}}>
            Save
          </Button>
          <Button variant="outline-secondary" onClick={handleEditCancel}>
            Cancel
          </Button>
        </div>
      ) : (
        <PostContent content={post.Content}/> // Use the PostContent component here
      )}
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
        {/* Left side with edit, delete, and comment icons */}
        <div>
          <ButtonGroup style={{marginTop: '5px', marginBottom: '5px'}}>
            {username === post.UserID && !isEditing && (
              <>
                <Button variant="link" onClick={handleEditClick} className="p-0" style={{marginRight: '5px'}}>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-edit`}>Edit</Tooltip>}
                  >
                    <Image
                      src={pencilIcon}
                      alt="Edit"
                      className="img-fluid icon"
                      style={{width: '36px', height: 'auto'}}
                    />
                  </OverlayTrigger>
                </Button>
                <Button variant="link" onClick={handleDelete} className="p-0" style={{marginRight: '5px'}}>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-delete`}>Delete</Tooltip>}
                  >
                    <Image
                      src={deleteIcon}
                      alt="Delete"
                      className="img-fluid icon"
                      style={{width: '36px', height: 'auto'}}
                    />
                  </OverlayTrigger>
                </Button>
              </>
            )}
            {!showCommentForm && (
              <Button variant="link" onClick={toggleCommentForm} className="p-0">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-comment`}>Comment</Tooltip>}
                >
                  <Image
                    src={commentIcon}
                    alt="Comment"
                    className="icon img-fluid"
                    style={{width: '36px', height: 'auto'}}
                  />
                </OverlayTrigger>
              </Button>
            )}
          </ButtonGroup>
        </div>

        {/* Right side with shaka icon and likes count */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="link"
            className={`p-0 shaka-button ${isShakaClicked ? 'ripple' : ''}`}
            onClick={handleShakaClick}
          >
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id={`tooltip-like`}>Like</Tooltip>}
            >
              <Image
                src={shakaIcon}
                alt="Shaka"
                className="img-fluid icon"
                style={{width: '36px', height: 'auto'}}
              />
            </OverlayTrigger>
          </Button>
            {likes > 0 && (
              <span style={{ marginLeft: '8px' }}>x { likes }</span>
            )}
        </div>
      </div>

      {/* Comment form for the post */}
      {showCommentForm && !showFileInput && (
        <Stack direction="vertical" gap={2} className="Write" style={{marginTop: '10px'}}>
          <Form style={{flexGrow: 1}}>
            <InputFieldComments
              as="textarea"
              name="text"
              placeholder="Write your reply here..."
              value={commentText}
              onChange={handleTextareaChange} // Adjust the textarea height on change
              onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleCommentSubmit(e)} // Allow Enter key for submission
              // onKeyDown={handleCommentKeyDown} // Handle key down for carriage return
              style={{width: '100%', borderRadius: '8px', padding: '5px 10px', overflow: 'hidden'}}
              rows={1}
              // onFocus={handleTextareaFocus} // Expand textarea on focus
              // onBlur={handleTextareaBlur} // Shrink textarea if empty on blur
              // ref={textareaRef} // Ref for the textarea
              onFocus={(e) => e.target.rows = 3} // Expand rows on focus
              onBlur={(e) => !commentText && (e.target.rows = 1)} // Shrink rows if empty on blur
            />
          </Form>
          <Button variant="primary" onClick={handleCommentSubmit} style={{width: '100%'}}>
            Post
          </Button>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-upload`}>Add Files</Tooltip>}
          >
            <Image
              src={plusIcon}
              alt={'Switch to file upload'}
              roundedCircle
              style={{width: '24px', height: 'auto', cursor: isBeachcomber ? 'not-allowed' : 'pointer'}}
              onClick={toggleInputType}
            />
          </OverlayTrigger>
          <Button variant="link" onClick={toggleCommentFormCancel} className="p-0">
            Cancel
          </Button>
        </Stack>
      )}
      {showCommentForm && showFileInput && (
        <FileUploadAndComment
          toggleInputType={toggleInputType}
          showComment={(newComment) => {

            post.Comments = [...post.Comments, newComment];
            // Propagate the updated post back to the parent
            updatePostInParent(post);

            setShowCommentForm(false);
          }}
          initialText={commentText}
          parentPostId={post.PostID}
        />
      )}
      {comments.map((comment, index) => (
        <Comment key={index} comment={comment} opID={post.PostID}/>
      ))}
      <UpgradeModal show={showUpgradeModal} handleClose={handleCloseModal}/>
    </div>
  );
}
