import config from "./config";

export default class ApiClient {
  // constructor({ token, refreshToken, logout, onError }) {
  constructor({ token, logout, onError }) {
    this.token = token;
    // this.refreshToken = refreshToken;
    this.logout = logout;
    this.onError = onError;
    this.base_url = config.api_base_url;
  }

  async request(options) {
    let response = await this.requestInternal(options, this.token);

    if (response.status === 401) {
      // // Attempt to refresh the token on a 401 error
      // const newToken = await this.refreshToken();
      // if (newToken) {
      //   // Retry the request with the new token
      //   response = await this.requestInternal(options, newToken);
      // } else {
      //   // Logout if unable to refresh the token
      //   this.logout();
      // }
      this.logout();
    }

    if (response.status >= 500 && this.onError) {
      this.onError(response);
    }

    return response;
  }

  async requestInternal(options, token) {
    let query = new URLSearchParams(options.query || {}).toString();
    if (query !== '') {
      query = '?' + query;
    }

    let response;
    try {
      response = await fetch(this.base_url + options.url + query, {
        method: options.method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          ...options.headers,
        },
        credentials: options.url === '/tokens' ? 'include' : 'omit',
        body: options.body ? JSON.stringify(options.body) : null,
      });
    } catch (error) {
      response = {
        ok: false,
        status: 500,
        json: async () => ({
          code: 500,
          message: 'The server is unresponsive',
          description: error.toString(),
        })
      };
    }

    return {
      ok: response.ok,
      status: response.status,
      body: response.status !== 204 ? await response.json() : null
    };
  }

  async get(url, query, options = {}) {
    return this.request({ method: 'GET', url, query, ...options });
  }

  async post(url, body, options = {}) {
    return this.request({ method: 'POST', url, body, ...options });
  }

  async put(url, body, options = {}) {
    return this.request({ method: 'PUT', url, body, ...options });
  }

  async delete(url, options = {}) {
    return this.request({ method: 'DELETE', url, ...options });
  }
}
