import React, { createContext, useContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import Cookies from 'js-cookie';
import config from '../config';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState(null);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const checkAuthentication = () => {
      const urlParams = new URLSearchParams(window.location.hash.substring(1));
      let token = urlParams.get('id_token');

      if (!token) {
        // Check cookies for token if not in URL
        token = Cookies.get('id_token');
        if (!token) {
          setIsAuthChecked(true); // Auth check done
          return;
        }
      } else {
        // Save token to cookies
        Cookies.set('id_token', token, { secure: true, sameSite: 'Strict', expires: 1 });
      }

      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          // Token has expired
          Cookies.remove('id_token');
          setIsAuthChecked(true); // Auth check done
          return;
        }

        const decodedUsername = decodedToken['cognito:username'];
        setUsername(decodedUsername);
        setToken(token);
        setIsAuthChecked(true); // Auth check done
      } catch (error) {
        console.error('Error validating JWT token:', error);
        // Token is invalid
        Cookies.remove('id_token');
        setIsAuthChecked(true); // Auth check done
      }
    };

    checkAuthentication();
  }, []);

  const isAuthenticated = () => {
    return username !== null;
  };

  const login = () => {
    const loginUrl = `https://${encodeURIComponent(config.authenticationDomain)}/login?client_id=${config.clientId}&response_type=token&scope=email+openid+phone&redirect_uri=${encodeURIComponent(config.redirectLoginUri)}`;
    window.location.href = loginUrl;
  };

  const join = () => {
    const joinUrl = `https://${encodeURIComponent(config.authenticationDomain)}/signup?client_id=${config.clientId}&response_type=token&scope=email+openid+phone&redirect_uri=${encodeURIComponent(config.redirectLoginUri)}`;
    window.location.href = joinUrl;
  };


  const logout = () => {
    const logoutUrl = `https://${encodeURIComponent(config.authenticationDomain)}/logout?client_id=${config.clientId}&logout_uri=${encodeURIComponent(config.redirectLogoutUri)}`;
    window.location.href = logoutUrl;
    setUsername(null);
    Cookies.remove('id_token'); // Remove token from cookies on logout
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, join, username, isAuthChecked, token }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
