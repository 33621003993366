import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    :root {
        --background-color: ${({ theme }) => theme.colors.background};
        --text-color: ${({ theme }) => theme.colors.text};
        --light-blue: ${({ theme }) => theme.colors.lightBlue};
        --link-color: ${({ theme }) => theme.colors.link};
        --button-color: ${({ theme }) => theme.colors.button};
        --navbar-link-color: ${({ theme }) => theme.colors.navbarLink || '#000'};
        --navbar-link-hover-color: ${({ theme }) => theme.colors.navbarLinkHover || theme.colors.button};
        --header-font: ${({ theme }) => theme.fonts.header};
        --base-font: ${({ theme }) => theme.fonts.base};
        --border-color: ${({ theme }) => theme.colors.border || '#e9ecef'};
        --focus-border-color: ${({ theme }) => theme.colors.focusBorder || theme.colors.button};
        --ripple-color: ${({ theme }) => theme.colors.ripple || 'rgba(70, 130, 180, 0.4)'};
    }

    body {
        background-color: var(--background-color);
        color: var(--text-color);
        font-family: var(--base-font);
    }
`;

export default GlobalStyle;
