const theme = {
  colors: {
    text: '#32628A',  // Dark teal for text
    background: '#DEECEF',  // Soft light blue for background
    // button: '#FF6600',  // Bright orange for buttons
    // button: '#32628A',
    lightBlue: '#9FCCF0',  // Light blue for accents
    link: '#007bff',  // Blue for links
    navbarLink: '#000000',  // Black for navbar links
    navbarLinkHover: '#E67E22',  // Bright orange for navbar link hover
    border: '#e9ecef',  // Light gray for borders
    focusBorder: '#E67E22',  // Orange for input focus borders
    ripple: 'rgba(70, 130, 180, 0.4)',  // Light blue for ripple effect
    orange: '#E67E22',
    blue: '#0090EA',
    green: '#62B603',
    red: '#F11700',
    turquoise: '#40E0D0',   // lighter turquoise
    // turquoise: '#20c5b5',   // slightly darker turquoise
    // turquoise: '#30C3B5',  // darker turquoise
    teal: '#008080'
  },
  fonts: {
    base: 'Lato, sans-serif',  // Base font for body text
    // header: 'Righteous',  // Fun, cursive font for headers
    header: 'Bungee',  // Fun, cursive font for headers
    // header: 'Kahuna, cursive',  // Fun, cursive font for headers
  },
};

export default theme;
