import { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useFlash } from './FlashProvider';
import config from "../config";
import {NavLink} from "react-router-dom";
import Nav from "react-bootstrap/Nav";

const UserContext = createContext();

export default function UserProvider({ children }) {
  const [user, setUser] = useState();
  const { isAuthenticated, token, isAuthChecked } = useAuth(); // Add isAuthChecked to ensure auth check is complete
  const flash = useFlash();

  useEffect(() => {
    const fetchUser = async () => {
      const url = config.aws_lambda_user_data_get;
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: headers
        });

        if (response.ok) {
          const user_data = await response.json();
          setUser(user_data);
          // Check if there are any invitations received
          if (user_data.data.invitations_received && user_data.data.invitations_received.length > 0) {
            const message = (
              <span>
                  You have{' '}
                    <Nav.Link
                      as={NavLink}
                      to="/friends"
                      style={{ textDecoration: 'underline', color: 'inherit', display: 'inline' }}
                    >
                      {user_data.data.invitations_received.length} new invitation(s)
                    </Nav.Link>
              </span>
            );
            flash(message, 'primary', 300);
          }
        } else {
          console.log('Failed to get user data.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (isAuthChecked && isAuthenticated) {
      fetchUser();
    }
  }, [isAuthenticated, token, isAuthChecked, flash]); // Include all necessary dependencies

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
