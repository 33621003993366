import React from 'react';
import { Container, Row } from 'react-bootstrap';
import SubscriptionTierBeachcomber from '../components/SubscriptionTierBeachcomber';
import SubscriptionTierIslander from '../components/SubscriptionTierIslander';
import SubscriptionTierWayfinder from '../components/SubscriptionTierWayfinder';
import { useUser } from "../contexts/UserProvider";
import Spinner from "react-bootstrap/Spinner";

const SubscriptionsPage = () => {
  const { user } = useUser();

  if (!user || !user.data) {
    return <Spinner animation="border" size="sm" />;
  }

  const { subscription_level } = user.data;

  return (
    <Container className="mt-5">
      {subscription_level === 'beachcomber' && (
      <h1 className="text-center">🌴 Join the Tribe! 🌴</h1>
      )}
      {subscription_level === 'beachcomber' && (
      <p className="text-center">
        Get ready to dive into an island paradise of connection and creativity. Choose your adventure below and let's start building your Tiki Tribe experience today!
      </p>
      )}

      {subscription_level === 'islander' && (
        <h1 className="text-center">🌴Continue your Adventure! 🌴</h1>
      )}
      {/*{subscription_level === 'islander' && (*/}
      {/*  <p className="text-center">*/}
      {/*    Continue your adventure with more storage and unlimited posts!*/}
      {/*  </p>*/}
      {/*)}*/}

      <Row className="mt-4">
        {/* Show Beachcomber tier if subscription_level is 'beachcomber' */}
        {subscription_level === 'beachcomber' && (
          <>
          <SubscriptionTierBeachcomber subscription_type={subscription_level} />
          <SubscriptionTierIslander subscription_type={subscription_level} />
          <SubscriptionTierWayfinder subscription_type={subscription_level} />
          </>
        )}

        {/* Show Islander and Wayfinder tiers if subscription_level is 'islander' */}
        {subscription_level === 'islander' && (
          <>
            <SubscriptionTierIslander subscription_type={subscription_level} />
            <SubscriptionTierWayfinder subscription_type={subscription_level} />
          </>
        )}

          {subscription_level === 'wayfinder' && (          <>
          <SubscriptionTierWayfinder subscription_type={subscription_level} />
          </>
        )}
      </Row>
      {subscription_level === 'beachcomber' && (
      <>
        <h4 className="text-center mt-5">🎉 Why Join the Tribe?</h4>
        <p className="text-center">
          At Tiki Tribe, we believe in creating a safe, ad-free, and vibrant space where you can share your stories and live your island dream. Your membership directly supports our mission to keep this community flowing with good vibes and sunshine!
        </p>
      </>
      )}
    </Container>
  );
};

export default SubscriptionsPage;
