import React, {useEffect, useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useApi} from '../contexts/ApiProvider';
import {useAuth} from '../contexts/AuthContext';
import {useUser} from "../contexts/UserProvider";
import Spinner from "react-bootstrap/Spinner";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import {format} from 'date-fns';
import UpgradeModal from '../components/UpgradeModal';
import {useOnlineStatus} from '../contexts/OnlineStatusProvider';

function identifyMimeType(url) {
  if (!url) return null;
  const extension = url.split('.').pop().split('?')[0].toLowerCase();
  const mimeTypes = {
    'mp4': 'video',
    'm4v': 'video',
    'mkv': 'video',
    'mov': 'video',
    'avi': 'video',
    'flv': 'video',
    'wmv': 'video',
    'jpg': 'image',
    'jpeg': 'image',
    'png': 'image',
    'gif': 'image',
    'bmp': 'image',
    'svg': 'image',
    'webp': 'image'
  };

  return extension in mimeTypes ? mimeTypes[extension] : `extension ${extension} not in known file types`;
}

const UserMediaGallery = () => {
  const {user} = useUser();
  const [mediaList, setMediaList] = useState([]);
  const [oldestTimestamp, setOldestTimestamp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const api = useApi();
  const {token} = useAuth();
  const navigate = useNavigate();
  const isOnline = useOnlineStatus(); // Get online status

  const fetchMedia = useCallback(async (lastTimestamp = null) => {
    setLoading(true);

    const url = '/tt_media_gallery_get';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    if (lastTimestamp) {
      headers['x-timestamp'] = lastTimestamp;
    }

    try {
      const response = await api.get(url, null, {headers}, token);

      if (response.ok) {
        const data = response.body.data;

        if (data.length > 0) {
          setMediaList((prevList) => lastTimestamp ? [...prevList, ...data] : data);
          setOldestTimestamp(data[data.length - 1].uploadedAt);
          setHasMore(response.body.hasMore);
        } else {
          setHasMore(false);
        }
      } else {
        console.error('Failed to fetch media:', response);
      }
    } catch (error) {
      console.error('Error fetching media:', error);
    } finally {
      setLoading(false);
    }
  }, [token, api]);

  useEffect(() => {
    fetchMedia(); // Initial fetch for media
  }, [fetchMedia]);

  const loadMore = () => {
    if (hasMore && !loading) {
      fetchMedia(oldestTimestamp);
    }
  };

  const handleThumbnailClick = (media) => {
    if (user?.data?.subscription_level === 'beachcomber') {
      setShowUpgradeModal(true);
    } else if (media.originalUrl) {
      setSelectedMedia(media);
    } else {
      console.error("Media does not have an originalUrl");
    }
  };

  const handleCloseModal = () => setShowUpgradeModal(false);

  const handleSubscribeClick = () => {
    navigate('/subscriptions');
  };

  if (user?.data?.subscription_level === 'beachcomber') {
    return (
      <Container>
        {isOnline ? (
          <div className="media-gallery" style={{textAlign: 'center', marginTop: '50px'}}>
            <h1>Explore the Full Adventure!</h1>
            <p style={{maxWidth: '600px', margin: '20px auto', fontSize: '1.2rem'}}>
              Only Islanders and Wayfinders can share and view their treasures in the Media Gallery. Upgrade your
              journey to unlock this privilege and see what’s waiting for you!
              <br/><br/>
              Mahalo for supporting a community that's ad-free and fiercely private.
            </p>
            <Button onClick={handleSubscribeClick} variant="primary" style={{marginTop: '20px'}}>
              Join the Tribe Adventure
            </Button>
          </div>
        ) : (
          // Fallback content when offline
          <div className="text-center my-4">Media Gallery is unavailable.</div>
        )}
      </Container>
    );
  }

  return (
    <Container>
      {isOnline ? (
        <div className="media-gallery" style={{textAlign: 'center'}}>
          <h1>Gallery</h1>
          {selectedMedia ? (
            <div className="selected-media">
              {identifyMimeType(selectedMedia.originalUrl) === 'video' ? (
                <video controls style={{maxWidth: '100%', margin: '20px auto'}}>
                  <source src={selectedMedia.originalUrl} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={selectedMedia.originalUrl}
                  alt="Selected Media"
                  style={{maxWidth: '100%', margin: '20px auto'}}
                />
              )}
              <Button onClick={() => setSelectedMedia(null)} variant="secondary" style={{marginTop: '20px'}}>
                Back to Thumbnails
              </Button>
            </div>
          ) : (
            <>
              {mediaList.length === 0 && !loading ? (
                <p>No media uploaded yet.</p>
              ) : (
                <div className="media-thumbnails d-flex justify-content-center flex-wrap">
                  {mediaList.map((media, index) => (
                    <div
                      key={index}
                      className="thumbnail-item"
                      style={{margin: '10px', cursor: 'pointer'}}
                      id={`media-${index}`}
                    >
                      <img
                        src={media.thumbnailUrl}
                        alt={`Media ${index}`}
                        className="thumbnail-image"
                        style={{width: '150px', height: '150px', objectFit: 'cover'}}
                        onClick={() => handleThumbnailClick(media)}
                        onError={(e) => {
                          document.getElementById(`media-${index}`).style.display = 'none';
                        }}
                      />
                      <p className="media-timestamp">
                        {format(new Date(media.uploadedAt), 'MMMM d, yyyy')}
                      </p>
                    </div>
                  ))}
                </div>
              )}

              {hasMore && (
                <div className="d-flex justify-content-center my-3">
                  <Button
                    onClick={loadMore}
                    variant="outline-primary"
                    className="load-more-btn"
                    disabled={loading}
                    style={{
                      marginBottom: '40px'
                    }}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        Loading...
                      </>
                    ) : (
                      'Previous Media'
                    )}
                  </Button>
                </div>
              )}
            </>
          )}

          <UpgradeModal show={showUpgradeModal} handleClose={handleCloseModal}/>
        </div>
      ) : (
        // Fallback content when offline
        <div className="text-center my-4">Media Gallery is unavailable.</div>
      )}
    </Container>
  );
};

export default UserMediaGallery;
