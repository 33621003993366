import React, { useState, useEffect, useCallback } from 'react';
import Body from '../components/Body';
import { useAuth } from '../contexts/AuthContext';
import { useApi } from '../contexts/ApiProvider';
import { useUser } from '../contexts/UserProvider';
import Image from "react-bootstrap/Image";
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import UpgradeModal from '../components/UpgradeModal';
import { useOnlineStatus } from '../contexts/OnlineStatusProvider';

const FriendsPage = () => {
  const [friends, setFriends] = useState([]);
  const [sentInvitations, setSentInvitations] = useState([]);
  const [receivedInvitations, setReceivedInvitations] = useState([]);
  const [inviteEmail, setInviteEmail] = useState('');
  const { token } = useAuth();
  const { user } = useUser();
  const api = useApi();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const isOnline = useOnlineStatus();

  const fetchFriendsData = useCallback(async () => {
    if (!isOnline) {
      console.warn("You're offline. Cannot fetch friends data.");
      return;
    }

    const url = '/tt_friends_get';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    try {
      const response = await api.get(url, null, { headers }, token);
      if (response.ok && response.body) {
        setFriends(response.body.friends || []);
        setSentInvitations(response.body.invitations_sent || []);
        setReceivedInvitations(response.body.invitations_received || []);
      } else {
        console.error('Failed to fetch friends:', response);
      }
    } catch (error) {
      console.error('Error fetching friends data:', error);
    }
  }, [token, api, isOnline]);

  useEffect(() => {
    fetchFriendsData();
  }, [fetchFriendsData]);

  const handleInvite = async () => {
    if (user?.data?.subscription_level === 'beachcomber') {
      setShowUpgradeModal(true);
      return;
    }

    if (inviteEmail) {
      const url = '/tt_invitation_send';
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      try {
        const response = await api.post(url, { email: inviteEmail }, { headers }, token);
        if (response.ok) {
          fetchFriendsData();
          setInviteEmail('');
        } else {
          console.error('Failed to send invite:', response);
        }
      } catch (error) {
        console.error('Error sending invitation:', error);
      }
    }
  };

  const handleRemoveFriend = async (friendEmail) => {
    if (!window.confirm("Are you sure you want to remove this friend?")) return;

    const url = '/tt_friend_remove';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    try {
      const response = await api.post(url, { email: friendEmail }, { headers }, token);
      if (response.ok) {
        fetchFriendsData();
      } else {
        console.error('Failed to remove friend:', response);
      }
    } catch (error) {
      console.error('Error removing friend:', error);
    }
  };

  const handleCancelInvitation = async (invitationId) => {
    const url = '/tt_invitation_cancel';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    try {
      const response = await api.post(url, { email: invitationId }, { headers }, token);
      if (response.ok) {
        fetchFriendsData();
      } else {
        console.error('Failed to cancel invitation:', response);
      }
    } catch (error) {
      console.error('Error canceling invitation:', error);
    }
  };

  const handleAcceptInvitation = async (invitationId) => {
    const url = '/tt_invitation_accept';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    try {
      const response = await api.post(url, { email: invitationId }, { headers }, token);
      if (response.ok) {
        fetchFriendsData();
      } else {
        console.error('Failed to accept invitation:', response);
      }
    } catch (error) {
      console.error('Error accepting invitation:', error);
    }
  };

  const handleDenyInvitation = async (invitationId) => {
    const url = '/tt_invitation_deny';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    try {
      const response = await api.post(url, { email: invitationId }, { headers }, token);
      if (response.ok) {
        fetchFriendsData();
      } else {
        console.error('Failed to deny invitation:', response);
      }
    } catch (error) {
      console.error('Error denying invitation:', error);
    }
  };

  const handleCloseModal = () => setShowUpgradeModal(false);

  return (
    <Container>
      {isOnline ? (
        <Body>
          <h2 className="text-center mb-4" >Your Tiki Tribe</h2>
          <p className="text-center mb-4" style={{ fontSize: '1.2rem' }}>
            Welcome to your Tiki Tribe! This is where your closest friends gather—invite new members or
            accept invitations to grow your trusted circle.
          </p>

          <div className="d-flex flex-column flex-md-row mb-4 justify-content-center align-items-center">
            <Form.Control
              type="email"
              value={inviteEmail}
              onChange={(e) => setInviteEmail(e.target.value)}
              placeholder="Enter email address to invite...📧🌴"
              className="mb-2 mb-md-0"
              style={{ flex: 1, maxWidth: '300px' }}
            />
            <Button variant="outline-primary" onClick={handleInvite} className="ml-md-2 mt-2 mt-md-0">
              Invite
            </Button>
          </div>

          <p className="text-center mb-4">or</p>
          <div className="text-center mb-5">
            <Button
              variant="outline-primary"
              onClick={() => {
                if (user?.data?.subscription_level === 'beachcomber') {
                  setShowUpgradeModal(true);
                } else {
                  window.location.href = "/friends_search";
                }
              }}
            >
              Invite friends from tiki search list... 🌺🤝
            </Button>
          </div>

          {receivedInvitations.length > 0 && (
            <div className="mb-4">
              <h3 className="text-center">Invitations Received</h3>
              <ul className="list-unstyled">
                {receivedInvitations.map((invitation) => (
                  <li key={invitation.email} className="mb-3">
                    <Card className="shadow-sm p-3 rounded">
                      <Stack gap={1} className="align-items-center flex-column flex-sm-row">
                        <Image
                          src={invitation.avatar_url || '/tt_icon.png'}
                          onError={(e) => { e.target.src = '/tt_icon.png'; }}
                          alt={'Avatar'}
                          roundedCircle
                          style={{ width: '48px', height: '48px', objectFit: 'cover' }}
                        />
                        <div className="flex-grow-1 text-center text-sm-left">
                          <span className="font-weight-bold">{invitation.username}</span>
                          <br />
                          <span>{invitation.email}</span>
                        </div>
                        <div className="w-100 d-sm-none" />
                        <div className="d-flex gap-2 mt-2 mt-sm-0">
                          <Button
                            size="sm"
                            variant="outline-info"
                            onClick={() => handleAcceptInvitation(invitation.email)}
                          >
                            Accept
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-danger"
                            onClick={() => handleDenyInvitation(invitation.email)}
                          >
                            Deny
                          </Button>
                        </div>
                      </Stack>
                    </Card>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {sentInvitations.length > 0 && (
            <div className="mb-4">
              <h3 className="text-center">Invitations Sent</h3>
              <ul className="list-unstyled">
                {sentInvitations.map((invitation) => (
                  <li key={invitation.email} className="mb-3">
                    <Card className="shadow-sm p-3 rounded">
                      <Stack gap={1} className="align-items-center flex-column flex-sm-row">
                        <div className="flex-grow-1 text-center text-sm-left">
                          <span className="font-weight-bold">{invitation.email}</span>
                        </div>
                        <div className="w-100 d-sm-none" />
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => handleCancelInvitation(invitation.email)}
                          className="mt-2 mt-sm-0"
                        >
                          Cancel Invitation
                        </Button>
                      </Stack>
                    </Card>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {friends.length > 0 && (
            <div className="mb-4">
              <h3 className="text-center">Friends</h3>
              <ul className="list-unstyled">
                {friends.map((friend) => (
                  <li key={friend.username} className="mb-3">
                    <Card className="shadow-sm p-3 rounded">
                      <Stack gap={1} className="align-items-center flex-column flex-sm-row">
                        <Image
                          src={friend.avatar_url || '/tt_icon.png'}
                          onError={(e) => { e.target.src = '/tt_icon.png'; }}
                          alt={'Avatar'}
                          roundedCircle
                          style={{ width: '48px', height: '48px', objectFit: 'cover' }}
                        />
                        <div className="flex-grow-1 text-center text-sm-left">
                          <Link to={'/member/' + friend.username}>
                            {friend.username}
                          </Link>
                          <br />
                          <span>{friend.email}</span>
                        </div>
                        <div className="w-100 d-sm-none" />
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => handleRemoveFriend(friend.email)}
                          className="mt-2 mt-sm-0"
                        >
                          Remove
                        </Button>
                      </Stack>
                    </Card>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <UpgradeModal show={showUpgradeModal} handleClose={handleCloseModal} />
        </Body>
      ) : (
        <div className="text-center my-4">Friends page is unavailable while offline.</div>
      )}
    </Container>
  );
};

export default FriendsPage;
