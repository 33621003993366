import React, {useState, useEffect} from 'react';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Stack from "react-bootstrap/Stack";
import Form from 'react-bootstrap/Form';
import {Image, ButtonGroup, Button, Spinner} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import TimeAgo from './TimeAgo';
import {useApi} from '../contexts/ApiProvider';
import {useAuth} from '../contexts/AuthContext';
import FileUploadAndComment from './FileUploadAndComment';
import InputFieldComments from './InputFieldComments';
import pencilIcon from '../assets/ps_edit.png';
import deleteIcon from '../assets/ps_delete.png';
import commentIcon from '../assets/ps_comment.png';
import plusIcon from '../assets/ps_plus.png';
import shakaIcon from '../assets/tt_shaka.png';
import UpgradeModal from '../components/UpgradeModal';
import {useUser} from "../contexts/UserProvider";
import PostContent from "./PostContent";

// // Utility function to check if running in PWA mode
// const isPWA = () => window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

export default function Comment({comment, opID, updateCommentInParent, removeCommentFromParent, addCommentToParent}) {
  const api = useApi();
  const {token, username} = useAuth();
  const {user} = useUser();
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(comment.Content);
  const [showFileInput, setShowFileInput] = useState(false);
  const [fullMediaObject, setFullMediaObject] = useState(null);
  const [loadingMedia] = useState(false);
  const [preloadedMedia, setPreloadedMedia] = useState(null);
  const [thumbnailVisible, setThumbnailVisible] = useState(true);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  // New state variables for likes, ripple effect, and PWA loading
  const [likes, setLikes] = useState(comment.Likes || 0);
  const [isShakaClicked, setIsShakaClicked] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false); // Track loading state for PWA submission

  // Check if user data is available and if user is a Beachcomber
  const isBeachcomber = user?.data?.subscription_level === 'beachcomber';

  const toggleInputType = () => {
    if (isBeachcomber) {
      setShowUpgradeModal(true); // Show the upgrade modal if the user is a Beachcomber
    } else {
      setShowFileInput(!showFileInput); // Toggle the file input for other users
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleEditSave = async () => {
    try {
      const url = '/tt_comments_edit';
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const response = await api.post(url, {
        PostID: comment.PostID,
        Content: editText
      }, {headers}, token);

      if (response.ok) {
        const updatedComment = response.body;
        setIsEditing(false);
        updateCommentInParent(updatedComment);
        alert('Comment updated successfully!');
      } else {
        alert('Failed to update comment. Please try again.');
      }
    } catch (error) {
      console.error('Error updating comment:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleEditCancel = () => {
    setIsEditing(false);
    setEditText(comment.Content);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      try {
        const url = '/tt_comment_delete';
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };

        const response = await api.post(url, {
          PostID: comment.PostID,
          ParentPostID: opID
        }, {headers}, token);

        if (response.ok) {
          removeCommentFromParent(comment.PostID);
          alert('Comment deleted successfully!');
        } else {
          alert('Failed to delete comment. Please try again.');
        }
      } catch (error) {
        console.error('Error deleting comment:', error);
        alert('An error occurred. Please try again.');
      }
    }
  };

  const toggleReplyForm = () => {
    if (isBeachcomber) {
      setShowUpgradeModal(true); // Show the upgrade modal if the user is a Beachcomber
    } else {
      setShowReplyForm(!showReplyForm);
    }
  };

  const toggleReplyFormCancel = () => setShowReplyForm(false);

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    const text = replyText.trim();
    if (!text) {
      return;
    }
    const requestBody = {
      ParentPostID: opID,
      CommentParentID: comment.PostID,
      text: text
    };
    try {
      const url = '/tt_comments_post';
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      // setIsSubmitting(true); // Set loading state
      const response = await api.post(url, requestBody, { headers }, token);

      if (response.ok) {
        const newComment = response.body;
        // if (!isPWA()) {
        //   addCommentToParent(newComment); // Only add to parent if not in PWA mode
        // }
        addCommentToParent(newComment);
        setReplyText('');
        setShowReplyForm(false);
        alert('Reply submitted successfully!');
      } else {
        alert('Failed to submit reply. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting reply:', error);
      alert('An error occurred. Please try again.');
    // } finally {
    //   setIsSubmitting(false); // Reset loading state
    }
  };
  // New function to handle the Shaka button click
  const handleShakaClick = async () => {
    // Trigger the ripple effect
    setIsShakaClicked(true);

    // Increase the likes locally
    setLikes((prevLikes) => prevLikes + 1);

    // Make the API call to increase the like count
    try {
      const response = await api.post(
        '/tt_like_increase_comment',
        { PostID: comment.PostID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
        token
      );

      if (!response.ok) {
        console.error('Failed to increase like count');
      }
    } catch (error) {
      console.error('Error increasing like count:', error);
    }

    // Reset the ripple effect after animation completes
    setTimeout(() => setIsShakaClicked(false), 600); // Adjust timing as per your CSS animation
  };
  useEffect(() => {
    const preloadMedia = async () => {
      if (comment.OriginalMediaUpload && user?.data?.subscription_level !== 'beachcomber') {
        try {
          const response = await api.post('/tt_s3_url_large_file_comment', {PostID: comment.PostID}, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });

          if (response.ok) {
            const presignedUrl = response.body.presignedUrl;
            setPreloadedMedia(presignedUrl);
          } else {
            console.error('Failed to preload media. Please try again.');
          }
        } catch (error) {
          console.error('Error preloading media:', error);
        }
      }
    };

    preloadMedia();
  }, [comment.OriginalMediaUpload, comment.PostID, api, token, user?.data?.subscription_level]);

  const handleMediaClick = () => {
    if (user?.data?.subscription_level === 'beachcomber') {
      setShowUpgradeModal(true); // Show modal instead of loading media
    } else if (preloadedMedia) {
      setFullMediaObject(preloadedMedia);
      setThumbnailVisible(false);
    }
  };

  const handleCloseModal = () => setShowUpgradeModal(false);

  if (!user || !user.data) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{
      marginBottom: '10px',
      borderLeft: '2px solid #d1e7ff',
      padding: '10px 15px',
      marginLeft: '15px',
      borderRadius: '8px',
      background: '#f9f9f9', // Light background for comments
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
    }}>
      {/* Comment header with avatar and username */}
      <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
        <Image
          src={comment.avatar_url || '/tt_icon.png'}
          onError={(e) => {
            e.target.src = '/tt_icon.png';
          }}
          alt={comment.UserID}
          roundedCircle
          style={{
            width: '30px',
            height: '30px',
            objectFit: 'cover',
            marginRight: '12px',
            border: '2px solid #e2e2e2'
          }}
        />
        <div>
          {username !== comment.UserID ? (
            <Link to={'/member/' + comment.UserID} style={{fontWeight: 'bold', color: '#007bff', fontSize: '14px'}}>
              {comment.UserID}
            </Link>
          ) : (
            <Link to={'/profile/'} style={{fontWeight: 'bold', color: '#007bff', fontSize: '14px'}}>
              {comment.UserID}
            </Link>
          )}
          &nbsp;&mdash;&nbsp;
          <TimeAgo isoDate={comment.Timestamp}/>
          {comment.Edited && <span style={{fontStyle: 'italic', color: '#6c757d'}}> (edited)</span>}
        </div>
      </div>

      {/* Media display section */}
      {comment.MediaObject && (
        <div style={{marginBottom: '12px'}}>
          {thumbnailVisible && (
            <img
              src={comment.MediaObject}
              alt="Media"
              style={{
                width: '100%',
                height: 'auto',
                cursor: 'pointer',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
              }}
              onClick={handleMediaClick}
            />
          )}
          {loadingMedia && <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>}

          {fullMediaObject && comment.MediaType === 'video' && (
            <video width="100%" controls style={{borderRadius: '8px'}}>
              <source src={fullMediaObject} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          )}

          {fullMediaObject && comment.MediaType === 'image' && (
            <img
              src={fullMediaObject}
              alt="Full Media"
              style={{width: '100%', height: 'auto', borderRadius: '8px'}}
            />
          )}
        </div>
      )}

      {/* Comment content and actions */}
      {isEditing ? (
        <div style={{marginTop: '8px'}}>
          <textarea
            value={editText}
            onChange={e => setEditText(e.target.value)}
            style={{
              width: '100%',
              height: '3em',
              padding: '5px 10px',
              borderRadius: '8px',
              border: '1px solid #ced4da'
            }}
          />
          <Button variant="outline-primary" onClick={handleEditSave} style={{marginRight: '5px'}}>
            Save
          </Button>
          <Button variant="outline-secondary" onClick={handleEditCancel}>
            Cancel
          </Button>
        </div>
      ) : (
        <PostContent content={comment.Content}/> // Use the PostContent component here
      )}

      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
        {/* Left side with edit, delete, and comment icons */}
        <div>
          <ButtonGroup style={{marginTop: '5px', marginBottom: '5px'}}>
            {username === comment.UserID && !isEditing && (
              <>
                <Button variant="link" onClick={handleEditClick} className="p-0" style={{marginRight: '5px'}}>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-edit`}>Edit</Tooltip>}
                  >
                    <Image
                      src={pencilIcon}
                      alt="Edit"
                      className="img-fluid icon"
                      style={{width: '36px', height: 'auto'}}
                    />
                  </OverlayTrigger>
                </Button>
                <Button variant="link" onClick={handleDelete} className="p-0" style={{marginRight: '5px'}}>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-delete`}>Delete</Tooltip>}
                  >
                    <Image
                      src={deleteIcon}
                      alt="Delete"
                      className="img-fluid icon"
                      style={{width: '36px', height: 'auto'}}
                    />
                  </OverlayTrigger>
                </Button>
              </>
            )}
            {!showReplyForm && (
              <Button variant="link" onClick={toggleReplyForm} className="p-0">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-comment`}>Comment</Tooltip>}
                >
                  <Image
                    src={commentIcon}
                    alt="Comment"
                    className="icon img-fluid"
                    style={{width: '36px', height: 'auto'}}
                  />
                </OverlayTrigger>
              </Button>
            )}
          </ButtonGroup>
        </div>

        {/* Right side with shaka icon and likes count */}
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button
            variant="link"
            className={`p-0 shaka-button ${isShakaClicked ? 'ripple' : ''}`}
            onClick={handleShakaClick}
          >
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id={`tooltip-like`}>Like</Tooltip>}
            >
              <Image
                src={shakaIcon}
                alt="Shaka"
                className="img-fluid icon"
                style={{width: '36px', height: 'auto'}}
              />
            </OverlayTrigger>
          </Button>
            {likes > 0 && (
              <span style={{ marginLeft: '8px' }}>x { likes }</span>
            )}
        </div>
      </div>

      {/* Reply form for the comment */}
      {showReplyForm && !showFileInput && (
        <Stack direction="vertical" gap={2} className="Write" style={{marginTop: '10px'}}>
          <Form onSubmit={handleReplySubmit} style={{flexGrow: 1}}>
            <InputFieldComments
              as="textarea"
              name="reply"
              placeholder="Write your reply here..."
              value={replyText}
              onChange={e => setReplyText(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleReplySubmit(e)} // Allow Enter key for submission
              style={{width: '100%', height: '3em', borderRadius: '8px', padding: '5px 10px'}}
              rows={1}
              onFocus={(e) => e.target.rows = 3} // Expand rows on focus
              onBlur={(e) => !replyText && (e.target.rows = 1)} // Shrink rows if empty on blur
            />
          </Form>
          {/*<Button variant="primary" onClick={handleReplySubmit} style={{ width: '100%' }}>*/}
          {/*  {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Post'}*/}
          <Button variant="primary" onClick={handleReplySubmit} style={{width: '100%'}}>
            Post
          </Button>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-upload`}>Add Files</Tooltip>}
          >
            <Image
              src={plusIcon}
              alt={'Switch to file upload'}
              roundedCircle
              style={{width: '24px', height: 'auto', cursor: isBeachcomber ? 'not-allowed' : 'pointer'}}
              onClick={toggleInputType}
            />
          </OverlayTrigger>
          <Button variant="link" onClick={toggleReplyFormCancel} className="p-0">
            Cancel
          </Button>
        </Stack>
      )}
      {showReplyForm && showFileInput && (
        <FileUploadAndComment
          toggleInputType={toggleInputType}
          showComment={addCommentToParent}
          initialText={replyText}
          parentPostId={opID}
          CommentParentID={comment.PostID}
        />
      )}
      <UpgradeModal show={showUpgradeModal} handleClose={handleCloseModal}/>
    </div>
  );
}
