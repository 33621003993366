import React, { useState, useEffect, useCallback } from 'react';
import Body from '../components/Body';
import StripeCustomerPortal from '../components/StripeCustomerPortal';
import { useUser } from "../contexts/UserProvider";
import { useAuth } from "../contexts/AuthContext";
import { useApi } from '../contexts/ApiProvider';
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ProgressBar from "react-bootstrap/ProgressBar";
import SubscriptionsPage from "./SubscriptionsPage";
import Card from "react-bootstrap/Card";
import ListGroup from 'react-bootstrap/ListGroup';

const AccountPage = () => {
  const { user } = useUser();
  const { token, username, logout } = useAuth();
  const api = useApi();
  const [storageUsed, setStorageUsed] = useState(0);
  const [postsSent, setPostsSent] = useState(0);
  const [pullDown, setPullDown] = useState(false);
  const [pullDownLoading, setPullDownLoading] = useState(false);
  const [pwaVersion, setPwaVersion] = useState('');

  // Determine max storage based on subscription level
  const maxStorage = user && user.data.subscription_level === 'wayfinder' ? 100 : 10;

  // Set up service worker message listener for version
  useEffect(() => {
    const handleServiceWorkerMessage = (event) => {
      if (event.data && event.data.type === 'VERSION') {
        setPwaVersion(event.data.version);
      }
    };

    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    // Remove listener on cleanup
    return () => {
      navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, []);

  // Fetch PWA version from service worker when it is ready
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        if (registration.active) {
          registration.active.postMessage({ type: 'GET_VERSION' });
        }
      });
    }
  }, []);

  const getAccountInfo = useCallback(async () => {
    setPullDownLoading(true); // Show spinner during data fetch
    try {
      const response = await api.get('/tt_account_info_get', null, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        setStorageUsed(response.body.data.s3_total_size / 1024); // Convert MB to GB
        setPostsSent(response.body.data.posts_created_last_month);
      } else {
        alert('Failed to get account info.');
      }
    } catch (error) {
      console.error('Error getting account info:', error);
    }
    setPullDownLoading(false); // Hide spinner after data fetch
  }, [token, api]);

  // Trigger refresh data when returning from Stripe portal
  const handleReturnFromStripe = () => {
    getAccountInfo();
  };

  useEffect(() => {
    getAccountInfo();
  }, [getAccountInfo]);

  // Pull-to-refresh logic
  useEffect(() => {
    let startY = 0;

    const onTouchStart = (e) => {
      startY = e.touches[0].clientY;
    };

    const onTouchMove = (e) => {
      const currentY = e.touches[0].clientY;
      if (currentY - startY > 50) { // Adjust threshold for refresh
        setPullDown(true);
      }
    };

    const onTouchEnd = () => {
      if (pullDown) {
        getAccountInfo(); // Refresh account data
      }
      setPullDown(false);
    };

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchend', onTouchEnd);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);
    };
  }, [pullDown, getAccountInfo]);

  const handleRemoveBeachcomberAccount = async () => {
    if (window.confirm("Are you sure you want to delete your account?")) {
      const reasonForDeletion = window.prompt("Please provide a reason for deleting your account (optional):");
      if (window.confirm("This action is irreversible. Are you absolutely sure?")) {
        try {
          const response = await api.post('/tt_account_delete_beachcomber', {
            reason: reasonForDeletion,
          }, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });

          if (response.ok) {
            alert('Account deletion request submitted successfully! Fair winds and following seas');
            setTimeout(() => {
              logout();
            }, 5000);
          } else {
            alert('Failed to submit account deletion request. Please try again.');
          }
        } catch (error) {
          console.error('Error submitting account deletion request:', error);
          alert('An error occurred. Please try again.');
        }
      }
    }
  };

  if (!user || !user.data) {
    return <div>Loading...</div>;
  }

  return (
    <Body>
      <Container>
        {pullDownLoading && (
          <div className="d-flex justify-content-center my-3">
            <Spinner animation="border" size="sm" />
            <span className="ms-2">Refreshing...</span>
          </div>
        )}

        {user.data.subscription_level === 'beachcomber' && (
          <Row className="d-flex justify-content-center align-items-center">
            <Col>
              <SubscriptionsPage/>
            </Col>
          </Row>
        )}

        <Row className="justify-content-center align-items-center mt-4 mb-4">
          <Col xs={12} md={8}>
            <Card className="shadow-lg">
              <Card.Body>
                <h2 className="text-center mb-4">Account Information</h2>
                <ListGroup variant="flush" className="text-left">
                  <ListGroup.Item><strong>Username:</strong> {username}</ListGroup.Item>
                  <ListGroup.Item><strong>Email Address:</strong> {user.data.email}</ListGroup.Item>
                  <ListGroup.Item><strong>Account Created:</strong> {user.data.cognito_account_date_created}</ListGroup.Item>

                  {user.data.subscription_level !== 'beachcomber' && (
                    <>
                      <ListGroup.Item><strong>Subscription Level:</strong> {user.data.subscription_level}</ListGroup.Item>
                      <ListGroup.Item><strong>Subscription Status:</strong> {user.data.subscription_status}</ListGroup.Item>
                      {user.data.subscription_level === 'islander' && (
                        <ListGroup.Item>
                          <strong>Posts created this Month</strong>
                          <ProgressBar now={postsSent} label={`${postsSent}`} max={100}/>
                        </ListGroup.Item>
                      )}
                      <ListGroup.Item>
                        <strong>Storage Used</strong>
                        <ProgressBar now={(storageUsed / maxStorage) * 100} label={`${storageUsed.toFixed(2)} GB`} />
                      </ListGroup.Item>
                      <ListGroup.Item><StripeCustomerPortal onReturn={handleReturnFromStripe} /></ListGroup.Item>
                    </>
                  )}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {user.data.subscription_level === 'beachcomber' && (
          <Row className="justify-content-center align-items-center mt-4 mb-4">
            <Col xs={12} md={8}>
              <Card className="shadow-lg tiki-theme-card">
                <Card.Body>
                  <h4 className="text-center mb-4">Remove Free Beachcomber Account</h4>
                  <ListGroup variant="flush" className="text-left">
                    <ListGroup.Item>
                      <p><strong>Warning:</strong> This action is irreversible, and you will lose access to your account.</p>
                      <Button variant="danger" onClick={handleRemoveBeachcomberAccount}>Remove Account</Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        {pwaVersion && (
          <ListGroup.Item><strong>Progressive Web App (PWA) Version:</strong> {pwaVersion}</ListGroup.Item>
        )}
      </Container>
    </Body>
  );
};

export default AccountPage;
