import React, { useState, useRef } from 'react';
import Body from '../components/Body';
import { useUser } from "../contexts/UserProvider";
import { useAuth } from "../contexts/AuthContext";
import { useApi } from '../contexts/ApiProvider';
import { Link } from 'react-router-dom';
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Card from 'react-bootstrap/Card';
import UpgradeModalFiles from '../components/UpgradeModalFiles';

const ProfilePage = () => {
  const { user, setUser } = useUser();
  const { token, username } = useAuth();
  const api = useApi();
  const [newProfilePic, setNewProfilePic] = useState(null);
  const fileInputRef = useRef(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const handleProfilePicChange = (event) => {
    setNewProfilePic(event.target.files[0]);
  };

  const triggerFileInput = () => {
    if (user?.data?.subscription_level === 'beachcomber') {
      setShowUpgradeModal(true);
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }
  };

  const updateProfilePic = async () => {
    if (user?.data?.subscription_level === 'beachcomber') {
      setShowUpgradeModal(true);
      return;
    }

    if (!newProfilePic) {
      alert('Please select a file.');
      return;
    }

    const timestamp = Date.now();
    const fileKey = `avatar-${username}-${timestamp}-${newProfilePic.name}`;

    try {
      const presignedUrlResponse = await api.get('/tt_s3_url_get', {
        file_name: encodeURIComponent(fileKey)
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      }, token);

      if (!presignedUrlResponse.ok) {
        alert('Failed to get the pre-signed URL.');
        return;
      }

      const { upload_url, original_object_name } = presignedUrlResponse.body;

      const uploadResult = await fetch(upload_url, {
        method: 'PUT',
        body: newProfilePic,
        headers: {
          'Content-Type': newProfilePic.type
        }
      });

      if (!uploadResult.ok) {
        alert('Failed to upload the file.');
        return;
      }

      const updateData = { avatar: original_object_name };

      const response = await api.post('/tt_user_profile_post', updateData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }, token);

      if (response.ok) {
        // Update the user context with both avatar and avatar_original from backend response
        setUser(prevUser => ({
          ...prevUser,
          data: {
            ...prevUser.data,
            avatar: response.body.avatar, // Update avatar
            avatar_original: response.body.avatar_original // Update avatar_original
          }
        }));
        alert('Profile pic updated');
      } else {
        alert('Failed to update profile.');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const toggleSearchableStatus = async () => {
    try {
      const newStatus = !user.data.user_searchable;
      const updateData = { user_searchable: newStatus };

      const response = await api.post('/tt_user_searchable', updateData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }, token);

      if (response.ok) {
        setUser(prevUser => ({
          ...prevUser,
          data: {
            ...prevUser.data,
            user_searchable: newStatus
          }
        }));
        alert(`Profile is now ${newStatus ? 'searchable' : 'hidden'} in Tiki Tribe.`);
      } else {
        alert('Failed to update profile searchable status.');
      }
    } catch (error) {
      console.error('Error updating searchable status:', error);
    }
  };

  const handleCloseModal = () => setShowUpgradeModal(false);

  if (!user || !user.data) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <Body>
      <Container>
        <Row className="justify-content-center mb-4">
          <Col xs={12} md={8}>
            <Card className="p-4 shadow-sm mb-4">
              <Row className="align-items-center">
                <Col xs={12} md={4} className="text-center mb-3 mb-md-0">
                  <Image
                    src={user.data.avatar_original || '/tt_icon.png'} // Initial fallback if avatar_original is null or undefined
                    onError={(e) => {
                      e.target.src = '/tt_icon.png'; // Fallback image if the avatar can't be loaded
                    }}
                    alt={user.data.username}
                    roundedCircle
                    style={{ width: '150px', height: '150px', objectFit: 'cover', border: '2px solid #e2e2e2' }}
                  />
                </Col>
                <Col xs={12} md={8} className="text-center text-md-left">
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'md-left' }}>
                    <Button variant="outline-primary" onClick={triggerFileInput} className="mb-2">
                      Select Picture
                    </Button>
                    <input
                      type="file"
                      id="file"
                      style={{ display: 'none' }}
                      onChange={handleProfilePicChange}
                      ref={fileInputRef}
                    />
                    {newProfilePic && (
                      <div style={{ fontSize: '0.9rem', marginBottom: '10px', color: '#6c757d' }}>
                        Selected file: {newProfilePic.name}
                      </div>
                    )}
                    <Button variant="outline-success" onClick={updateProfilePic}>
                      Update
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center mt-4">
                <Col xs={12} className="text-center">
                  <h4>
                    User Profile is Tiki Tribe Searchable: {user.data.user_searchable ? 'Yes' : 'No'}
                  </h4>
                  <Button
                    onClick={toggleSearchableStatus}
                    variant={user.data.user_searchable ? 'outline-primary' : 'outline-danger'}
                  >
                    {user.data.user_searchable ? 'Hide Profile from Tiki Tribe Search' : 'Make Profile Searchable in Tiki Tribe'}
                  </Button>
                </Col>
              </Row>
            </Card>

            <Card className="p-4 shadow-sm text-center">
              <h4>Your Island Adventures!</h4>
              <p className="mb-3">
                You can view all your posts in one place. Click below to see your island vibes in action!
              </p>
              <Button as={Link} to="/my_posts" variant="outline-primary">
                My Posts
              </Button>
            </Card>
          </Col>
        </Row>
      </Container>

      <UpgradeModalFiles show={showUpgradeModal} handleClose={handleCloseModal} />
    </Body>
  );
};

export default ProfilePage;
