import React, {useState, useEffect} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import {Link, NavLink} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import theme from '../theme';
import {useAuth} from '../contexts/AuthContext';
import menuIcon from '../assets/ps_menu.png';
import TikiIcon from '../assets/scene.jpg';

export default function Header() {
  const {isAuthenticated, login, logout, isAuthChecked} = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [navExpanded, setNavExpanded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Automatically expand the navbar on small screens when user is not authenticated
      if (window.innerWidth < 992 && !isAuthenticated()) {
        setNavExpanded(true);
      } else {
        setNavExpanded(false);
      }
    };

    // Check screen size on load and on resize
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isAuthenticated]);

  const handleToggleNavbar = () => {
    setNavExpanded(!navExpanded); // Toggle navbar expansion
  };

  const handleCloseNavbar = () => {
    setNavExpanded(false); // Collapse navbar when a link is clicked
  };

  const handleToggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <Navbar expand="lg" sticky="top" className="Header" expanded={navExpanded}>
      <Container>
        <Navbar.Brand
          className="d-flex align-items-center"
          style={{color: theme.colors.text, fontSize: '5rem', lineHeight: '1'}}
        >
          <Link
            to="/"
            style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', flexGrow: 1}}
          >
            <div style={{display: 'flex', alignItems: 'center', flexGrow: 0}}>
              <Image
                src={TikiIcon}
                roundedCircle
                style={{width: '15%', marginRight: '1rem', flexShrink: 0}}
              />
              <span className="d-none d-sm-block" style={{
                color: theme.colors.turquoise,
                fontSize: '6vw', // Font size scales with viewport width
                flexGrow: 1,
              }}>
    Tiki Tribe
  </span>

              <span className="d-sm-none" style={{
                fontSize: 'calc(6vw + 16px)', // Font size scales with viewport width
                color: theme.colors.turquoise,
                flexGrow: 1,
              }}>
    Tiki Tribe
  </span>
            </div>

          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggleNavbar}/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {!isAuthChecked ? (
              <Spinner animation="border"/>
            ) : (
              <>
                {isAuthenticated() ? (
                  <>
                    {/* Show the menuIcon only on larger screens */}
                    <div className="d-none d-lg-block">
                      <NavDropdown
                        title={
                          <Image
                            src={menuIcon}
                            alt="Menu"
                            roundedCircle
                            style={{width: '40px', height: 'auto'}}
                          />
                        }
                        align="end"
                        className="custom-dropdown"
                        show={dropdownOpen}
                        onToggle={handleToggleDropdown}
                      >
                        <NavDropdown.Item
                          as={NavLink}
                          to="/home"
                          className="custom-dropdown-item"
                          onClick={handleCloseDropdown}
                        >
                          Home
                        </NavDropdown.Item>
                        <NavDropdown.Divider/>
                        <NavDropdown.Item
                          as={NavLink}
                          to="/friends"
                          className="custom-dropdown-item"
                          onClick={handleCloseDropdown}
                        >
                          Friends
                        </NavDropdown.Item>
                        <NavDropdown.Divider/>
                        <NavDropdown.Item
                          as={NavLink}
                          to="/media_gallery"
                          className="custom-dropdown-item"
                          onClick={handleCloseDropdown}
                        >
                          Media Gallery
                        </NavDropdown.Item>
                        <NavDropdown.Divider/>
                        <NavDropdown.Item
                          as={NavLink}
                          to="/my_posts"
                          className="custom-dropdown-item"
                          onClick={handleCloseDropdown}
                        >
                          My Posts
                        </NavDropdown.Item>
                        <NavDropdown.Divider/>
                        <NavDropdown.Item
                          as={NavLink}
                          to="/profile"
                          className="custom-dropdown-item"
                          onClick={handleCloseDropdown}
                        >
                          Profile
                        </NavDropdown.Item>
                        <NavDropdown.Divider/>
                        <NavDropdown.Item
                          as={NavLink}
                          to="/account"
                          className="custom-dropdown-item"
                          onClick={handleCloseDropdown}
                        >
                          Account
                        </NavDropdown.Item>
                        <NavDropdown.Divider/>
                        <NavDropdown.Item
                          as={NavLink}
                          to="/download"
                          className="custom-dropdown-item"
                          onClick={handleCloseDropdown}
                        >
                          Download App
                        </NavDropdown.Item>
                        <NavDropdown.Divider/>
                        <NavDropdown.Item
                          onClick={() => {
                            logout();
                            handleCloseDropdown();
                          }}
                          className="custom-dropdown-item"
                        >
                          Logout
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>

                    {/* Show the menu items directly on smaller screens */}
                    <div className="d-lg-none">
                      <NavDropdown.Item
                        as={NavLink}
                        to="/home"
                        className="custom-dropdown-item"
                        onClick={handleCloseNavbar}
                      >
                        Home
                      </NavDropdown.Item>
                      <NavDropdown.Divider/>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/friends"
                        className="custom-dropdown-item"
                        onClick={handleCloseNavbar}
                      >
                        Friends
                      </NavDropdown.Item>
                      <NavDropdown.Divider/>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/media_gallery"
                        className="custom-dropdown-item"
                        onClick={handleCloseNavbar}
                      >
                        Media Gallery
                      </NavDropdown.Item>
                      <NavDropdown.Divider/>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/my_posts"
                        className="custom-dropdown-item"
                        onClick={handleCloseNavbar}
                      >
                        My Posts
                      </NavDropdown.Item>
                      <NavDropdown.Divider/>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/profile"
                        className="custom-dropdown-item"
                        onClick={handleCloseNavbar}
                      >
                        Profile
                      </NavDropdown.Item>
                      <NavDropdown.Divider/>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/account"
                        className="custom-dropdown-item"
                        onClick={handleCloseNavbar}
                      >
                        Account
                      </NavDropdown.Item>
                      <NavDropdown.Divider/>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/download"
                        className="custom-dropdown-item"
                        onClick={handleCloseNavbar}
                      >
                        Download App
                      </NavDropdown.Item>
                      <NavDropdown.Divider/>
                      <NavDropdown.Item
                        onClick={() => {
                          logout();
                          handleCloseNavbar();
                        }}
                        className="custom-dropdown-item"
                      >
                        Logout
                      </NavDropdown.Item>
                    </div>
                  </>
                ) : (
                  <>
                    <Nav.Link
                      onClick={login}
                      style={{color: theme.colors.text, fontSize: '1rem'}}
                    >
                      Login/Join
                    </Nav.Link>
                    {/*<Nav.Link*/}
                    {/*  href="/download_app"  // Link to another page or feature*/}
                    {/*  style={{ color: theme.colors.orange, fontSize: '1.5rem' }}*/}
                    {/*>*/}
                    {/*  Download App*/}
                    {/*</Nav.Link>*/}
                  </>
                )}
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
