import Body from '../components/Body';
import {NavLink} from "react-router-dom";
import Nav from "react-bootstrap/Nav";


export default function TermsConditionsPage() {
  return (
    <Body>
      <div>
        <h1>Terms and Conditions for Tiki Tribe</h1>
        <p><strong>Effective Date:</strong> October 26th, 2024</p>

        <p>Welcome to Tiki Tribe! By using our services, you agree to these Terms and Conditions. Please read them
          carefully.</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using Tiki Tribe (the "Service"), you agree to be bound by these Terms and Conditions (the
          "Terms"). If you do not agree to these Terms, you may not use the Service.</p>

        <h2>2. Description of Service</h2>
        <p>Tiki Tribe is a SaaS platform that allows users to share text, video, and picture posts. The Service is
          based on a tiered subscription model and emphasizes privacy by not selling user data or displaying
          ads.</p>

        <h2>3. Eligibility and Family Accounts</h2>
        <p>To use the Service, you must be at least 14 years old or have parental consent to create an account. Parents
          or legal guardians (collectively, "Parents") may create accounts for their children under the age of 14 (each,
          a "Child Account"). By creating a Child Account, Parents agree to supervise the child's use of the Service and
          are fully responsible for the child's activity on the Service.</p>

        <h2>4. Account Registration</h2>
        <p>To access certain features of the Service, you must register for an account. You agree to provide accurate
          and complete information during the registration process and to update such information to keep it accurate
          and complete. Parents creating Child Accounts must ensure that the accounts are used in accordance with these
          Terms.</p>

        <h2>5. User Responsibilities</h2>
        <p>You are responsible for maintaining the confidentiality of your account credentials and for all activities
          that occur under your account. Parents are responsible for maintaining the confidentiality of their Child
          Accounts and for all activities that occur under those accounts. You agree to notify us immediately of any
          unauthorized use of your account or any Child Accounts.</p>

        <h2>6. Privacy</h2>
        <p>Your privacy is important to us. Please review our <Nav.Link as={NavLink} to="/privacypolicy"
                                                                        className="privacy-policy-link">Privacy
          Policy</Nav.Link> to understand
          how we collect, use, and protect your information.</p>

        <h2>7. Subscription Plans and Payment</h2>
        <p>Tiki Tribe offers a tiered subscription model:</p>
        <ul>
          <li><strong>Beachcomber Service:</strong> Free. Users can accept friend invitations, view posts and comments,
            and submit "Likes".
          </li>
          <li><strong>Islander Subscription:</strong> Monthly flat fee with additional features including the ability
            to post and view pictures and videos. Resource limits apply.
          </li>
          <li><strong>Wayfinder Subscription:</strong> Monthly flat fee with additional features. Resource limits apply.
          </li>
        </ul>
        <p>We reserve the right to change subscription fees and service features at any time. If we change the fees or
          service features, we will provide notice of such changes by posting the updated Terms on our website or
          through other communications. Your continued use of the Service after the effective date of the revised Terms
          constitutes your acceptance of the revised Terms.</p>

        <h2>8. Prohibited Conduct</h2>
        <p>You agree not to engage in any of the following prohibited activities:</p>
        <ul>
          <li>Violating any applicable laws or regulations.</li>
          <li>Infringing the intellectual property rights of others.</li>
          <li>Posting or transmitting content that is offensive, harmful, or inappropriate.</li>
          <li>Using the Service for any commercial purposes without our prior written consent.</li>
          <li>Attempting to interfere with the operation of the Service.</li>
        </ul>

        <h2>9. Termination</h2>
        <p>We may terminate or suspend your account and access to the Service at our sole discretion, without prior
          notice, for conduct that we believe violates these Terms or is harmful to other users of the Service, us, or
          third parties, or for any other reason.</p>

        <h2>10. Limitation of Liability</h2>
        <p>To the fullest extent permitted by law, Tiki Tribe and its affiliates, officers, directors, employees,
          agents, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive
          damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data,
          use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the Service; (b)
          any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any
          interruption or cessation of transmission to or from the Service; (d) any bugs, viruses, Trojan horses, or the
          like that may be transmitted to or through the Service by any third party; (e) any errors or omissions in any
          content or for any loss or damage incurred as a result of your use of any content posted, emailed,
          transmitted, or otherwise made available through the Service; and/or (f) the defamatory, offensive, or illegal
          conduct of any third party.</p>

        <h2>11. Governing Law</h2>
        <p>These Terms shall be governed by and construed in accordance with the laws of the State of Florida, United
          States of America,
          without regard to its conflict of law principles.</p>

        <h2>12. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms at any time. If we make changes to these Terms, we will provide
          notice of such changes by posting the updated Terms on our website or through other communications. Your
          continued use of the Service after the effective date of the revised Terms constitutes your acceptance of the
          revised Terms.</p>

        <h2>13. Contact Information</h2>
        <p>If you have any questions about these Terms, please contact us at <a href="mailto:admin@tikitribe.net?subject=Terms%20and%20Conditions%20">admin@tikitribe.net</a>
          .</p>

      </div>
    </Body>
  );
}
