import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";

export default function SubscriptionTierBeachcomber({ subscription_type }) {
  return (
    <Col md={4} className="mb-4">
      <Card className="text-center h-100">
        <Card.Body>
          <h2>🍍 Beachcomber Tribe Guest</h2>
          <h3>Free</h3>
          <p>Welcome to the island, enjoy the view! 🏝️</p>
          <ul className="list-unstyled">
            <li>📸 View only thumbnails of posts</li>
            <li>👍 Like posts and comments</li>
            <li>❌ No ability to create your own posts or comments</li>
            <li>❌ No profile picture uploads</li>
          </ul>
          <Button variant="secondary" size="lg" disabled>
            You're on the Free Plan!
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}
