import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Card from 'react-bootstrap/Card';
import Button from "react-bootstrap/Button";

const DownloadApp = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isIos, setIsIos] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    console.log('Checking PWA status...');

    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIosDevice = /iphone|ipad|ipod/.test(userAgent);
    setIsIos(isIosDevice);
    console.log(`Is iOS: ${isIosDevice}`);

    // Check if the app is running in standalone mode (iOS and non-iOS)
    const isStandaloneMode =
      window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;

    setIsStandalone(isStandaloneMode);
    console.log(`Is Standalone (already installed): ${isStandaloneMode}`);

    const handleBeforeInstallPrompt = (e) => {
      console.log('beforeinstallprompt event detected');
      e.preventDefault(); // Prevent the default mini-infobar
      setDeferredPrompt(e); // Store the event for later use
      setIsInstallable(true);
    };

    // Add event listener for beforeinstallprompt
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      console.log('Prompting PWA installation');
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null); // Reset the deferred prompt
      });
    }
  };

  return (
    <Container className="my-5">
      <Card className="p-4 shadow-sm text-center">
        <h1>Install Tiki Tribe App</h1>

        {/*<p><strong>Debug Info:</strong></p>*/}
        {/*<p>Installable: {isInstallable ? 'true' : 'false'}</p>*/}
        {/*<p>Standalone: {isStandalone ? 'true' : 'false'}</p>*/}
        {/*<p>iOS: {isIos ? 'true' : 'false'}</p>*/}

        {/* App is already installed */}
        {isStandalone && (
          <div>
            <p>The Tiki Tribe app is already installed on your device!</p>
          </div>
        )}

        {/* App is installable and not already installed */}
        {!isStandalone && isInstallable && (
          <div>
            <p>You can install the Tiki Tribe app for easy access on your device.</p>
            <Button
              onClick={handleInstallClick}
              variant="outline-primary"
            >
              Install App
            </Button>
          </div>
        )}

        {/* iOS-specific instructions for installing as a PWA */}
        {isIos && !isStandalone && (
          <div>
            <h2>Install on iPhone or iPad</h2>
            <p>To install this app on your iPhone or iPad, follow these steps:</p>
            <ol>
              <li>Tap the <strong>Share</strong> button at the bottom of Safari.</li>
              <li>Scroll down and select <strong>Add to Home Screen</strong>.</li>
              <li>Tap <strong>Add</strong> in the top-right corner to add the app to your home screen.</li>
            </ol>
          </div>
        )}

        {/* If the app is not installable, not on iOS, and not installed */}
        {!deferredPrompt && !isIos && !isStandalone && !isInstallable && (
          <div>
            <p>Your browser doesn't support installing this app. You can access it directly from the web.</p>
          </div>
        )}
      </Card>
    </Container>
  );
};

export default DownloadApp;
