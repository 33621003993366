import React from 'react';
import Linkify from 'react-linkify';
import DOMPurify from 'dompurify';

const linkifyDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text.length > 30 ? `${text.slice(0, 27)}...` : text}
  </a>
);

const PostContent = ({ content }) => {
  // Sanitize user-generated content
  const sanitizedContent = DOMPurify.sanitize(content);

  // Replace line breaks with a placeholder (e.g., __BR__) so we can split and map over it
  const contentWithPlaceholders = sanitizedContent.replace(/\n/g, '__BR__');

  // Split content at placeholders and map to <br /> or text
  const parts = contentWithPlaceholders.split('__BR__');

  return (
    <div className="post-content" style={{ whiteSpace: 'pre-wrap' }}>
      <Linkify componentDecorator={linkifyDecorator}>
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index < parts.length - 1 && <br />} {/* Add <br /> for each line break */}
          </React.Fragment>
        ))}
      </Linkify>
    </div>
  );
};

export default PostContent;
