import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const UpgradeModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Join the Island Adventure!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Only Islanders and Wayfinders can explore this content. Upgrade to join the adventure and see what’s waiting for you!
          Tiki Tribe is ad-free and respects your privacy—your support helps keep it that way.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => window.location.href = '/subscriptions'}>
          Join the Adventure
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Maybe Later
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpgradeModal;
