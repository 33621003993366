import React, { useCallback, useState, useEffect } from "react";
import config from "../config.js";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { Navigate, useParams } from "react-router-dom";
import { useApi } from "../contexts/ApiProvider";
import {useAuth} from "../contexts/AuthContext";
import { Link as RouterLink } from 'react-router-dom';
import Body from "../components/Body";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.stripe_public);

export const CheckoutForm = () => {
  const { membership_type } = useParams();
  console.log(membership_type);
  const api = useApi();
  const { token } = useAuth();
  const fetchClientSecret = useCallback(async () => {
    try {
      // Create a Checkout Session using the API client
      const response = await api.post('/tt_stripe_checkout_subscription', {
        membership_type // Include membership type in the body
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = response.body;
        console.log('we have the client secret')
        console.log(data.clientSecret)
        return data.clientSecret;
      } else {
        alert('Failed to initiate checkout. Please try again.');
        return null;
      }
    } catch (error) {
      console.error('Error initiating checkout:', error);
      alert('An error occurred. Please try again.');
      return null;
    }
  }, [membership_type, api, token]);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const api = useApi();
  const { token } = useAuth();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    const fetchSessionStatus = async () => {
      console.log('we are in return')
      console.log('Auth Token:', token);
      try {
        const response = await api.post('/tt_stripe_checkout_session_status', { session_id: sessionId }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          const data = response.body;
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
        } else {
          alert('Failed to fetch session status. Please try again.');
        }
      } catch (error) {
        console.error('Error fetching session status:', error);
        alert('An error occurred. Please try again.');
      }
    };

    fetchSessionStatus();
  }, [api, token]);

  console.log(status);

  if (status === 'open') {
    return <Navigate to="/checkout" />;
  }

  if (status === 'complete') {
    return (
      <Body>
      <section id="success"
               style={{marginBottom: '100px', textAlign: 'center'}}>
        {/*<p style={{fontSize: '1.2em', color: '#2a8c8d'}}>*/}
        <p style={{fontSize: '1.2em'}}>
          Mahalo for being a part of the Tiki Tribe, {customerEmail}! 🌺 Your confirmation email is on its way, so get
          ready for some tropical vibes!
          <br/><br/>
          If you have any questions, feel free to drop us a message at
          <a href="mailto:admin@tikitribe.net"
             style={{color: '#e67e22', textDecoration: 'none'}}> admin@tikitribe.net</a>.
        </p>
        <p style={{fontSize: '1.2em'}}>
          Wishing you sunny skies and island adventures ahead! 🏝️🌊
        </p>

        {/* Button to go to home page */}
        <RouterLink to="/home" style={{textDecoration: 'none'}}>
          <button style={{
            marginTop: '20px',
            padding: '12px 24px',
            fontSize: '1.2em',
            backgroundColor: '#e67e22',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer'
          }}>
            Return to Your Island Home
          </button>
        </RouterLink>
      </section>
      </Body>
    );
  }

  return null;
};
