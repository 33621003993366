import React, { useState, useEffect, useRef } from 'react';
import Stack from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import Form from 'react-bootstrap/Form';
import { useAuth } from "../contexts/AuthContext";
import FileUploadAndPost from "./FileUploadandPost";
import { useUser } from "../contexts/UserProvider";
import plusIcon from '../assets/ps_plus.png';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useApi } from "../contexts/ApiProvider";
import UpgradeModal from '../components/UpgradeModal';
import Button from 'react-bootstrap/Button';

export default function Write({ showPost }) {
  const { token } = useAuth();
  const api = useApi();
  const { user } = useUser();
  const [formErrors, setFormErrors] = useState({});
  const textField = useRef();
  const [showFileInput, setShowFileInput] = useState(false);
  const [typedText, setTypedText] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [focused, setFocused] = useState(false); // Track if the text field is focused

  const toggleInputType = () => {
    const currentText = textField.current ? textField.current.value : typedText;
    setTypedText(currentText);
    setShowFileInput(!showFileInput);
  };

  useEffect(() => {
    if (textField.current) {
      textField.current.focus();
    }
  }, []);

  const onSubmit = async () => {
    if (isBeachcomber) {
      handleShowUpgradeModal();
      return;
    }

    const text = textField.current.value.trim();

    if (!text) {
      return;
    }
    try {
      const postData = { text };

      const response = await api.post('/tt_posts_post', postData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }, token);

      if (response.ok) {
        const responseData = response.body;
        showPost(responseData);
        textField.current.value = ''; // Clear the text field
        setFocused(false); // Reset the focus state
      } else {
        const errorData = response.body;
        if (errorData.errors) {
          setFormErrors(errorData.errors);
        }
      }
    } catch (error) {
      console.error('Error submitting post:', error);
    }
  };

  const handleShowUpgradeModal = () => setShowUpgradeModal(true);
  const handleCloseUpgradeModal = () => setShowUpgradeModal(false);

  const isBeachcomber = user?.data?.subscription_level === 'beachcomber';

  if (showFileInput) {
    return (
      <FileUploadAndPost
        toggleInputType={toggleInputType}
        showPost={showPost}
        initialText={typedText}
      />
    );
  }

  return (
    <>
      <div style={{
        padding: '15px',
        marginBottom: '20px',
        backgroundColor: '#ffffff',
        border: '1px solid #e2e2e2',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        maxWidth: '800px', // Limit the width for better readability on larger screens
        marginLeft: 'auto', // Center the container
        marginRight: 'auto'
      }}>
        <Stack direction="horizontal" gap={3} className="Write" style={{ alignItems: 'center' }}>
          <Image
            src={user?.data?.avatar || '/tt_icon.png'}
            onError={(e) => { e.target.src = '/tt_icon.png'; }}
            roundedCircle
            style={{
              width: '48px',
              height: '48px',
              objectFit: 'cover',
              border: '2px solid #e2e2e2',
              marginRight: '12px'
            }}
          />
          <Form style={{ flexGrow: 1 }}>
            <Form.Control
              as="textarea" // Use textarea for multiline support
              name="text"
              placeholder="What's the latest?"
              isInvalid={!!formErrors.text}
              ref={textField}
              onClick={isBeachcomber ? handleShowUpgradeModal : () => setFocused(true)}
              style={{
                borderRadius: '8px',
                border: '1px solid #ced4da',
                padding: '10px',
                fontSize: '16px',
                width: '100%',
                height: focused ? '100px' : '40px', // Adjust height based on focus state
                transition: 'height 0.2s ease-in-out',
                resize: 'none', // Disable manual resizing
              }}
            />
          </Form>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-upload`}>Add Files</Tooltip>}
          >
            <Image
              src={plusIcon}
              alt={'Switch to file upload'}
              roundedCircle
              style={{
                width: '40px',
                height: '40px',
                cursor: isBeachcomber ? 'not-allowed' : 'pointer'
              }}
              onClick={isBeachcomber ? handleShowUpgradeModal : toggleInputType}
            />
          </OverlayTrigger>
        </Stack>
        {focused && (
          <div className="d-flex justify-content-center mt-3">
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={isBeachcomber}
              style={{
                width: '100%', // Make the button as wide as the text field
                maxWidth: 'calc(100% - 60px)', // Ensure it doesn't exceed the container width
                marginTop: '10px'
              }}
            >
              Post
            </Button>
          </div>
        )}
      </div>

      {/* Render the UpgradeModal */}
      <UpgradeModal show={showUpgradeModal} handleClose={handleCloseUpgradeModal} />
    </>
  );
}
