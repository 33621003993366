import React, { useState, useEffect, useCallback } from 'react';
import Body from '../components/Body';
import { useAuth } from '../contexts/AuthContext';
import { useApi } from '../contexts/ApiProvider';
import Image from "react-bootstrap/Image";
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import { useUser } from "../contexts/UserProvider";
import Spinner from "react-bootstrap/Spinner";
import Card from 'react-bootstrap/Card';
import UpgradeModal from '../components/UpgradeModal';

const FriendsSearchPage = () => {
  const { user, setUser } = useUser();
  const [searchableUsers, setSearchableUsers] = useState([]);
  const [sentInvitations, setSentInvitations] = useState([]);
  const { token } = useAuth();
  const api = useApi();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const toggleSearchableStatus = async () => {
    try {
      const newStatus = !user.data.user_searchable;
      const updateData = { user_searchable: newStatus };

      const response = await api.post('/tt_user_searchable', updateData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }, token);

      if (response.ok) {
        const updatedUser = { ...user, data: { ...user.data, user_searchable: newStatus } };
        setUser(updatedUser);
        alert(`Profile is now ${newStatus ? 'searchable' : 'hidden'} in Tiki Tribe.`);
      } else {
        alert('Failed to update profile searchable status.');
      }
    } catch (error) {
      console.error('Error updating searchable status:', error);
    }
  };

  const fetchSearchableUsers = useCallback(async () => {
    const url = '/tt_user_searchable_find_friends';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await api.get(url, null, { headers }, token);
    if (response.ok) {
      setSearchableUsers(response.body.searchable_users);
      setSentInvitations(response.body.invitations_search_sent);
    } else {
      console.error('Failed to fetch searchable users:', response);
    }
  }, [token, api]);

  useEffect(() => {
    fetchSearchableUsers();
  }, [fetchSearchableUsers]);

  const handleSendInvitation = async (username) => {
    if (user?.data?.subscription_level === 'beachcomber') {
      setShowUpgradeModal(true);
      return;
    }
    const url = '/tt_invitation_send_searchable';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await api.post(url, { username }, { headers }, token);
    if (response.ok) {
      fetchSearchableUsers();
    } else {
      console.error('Failed to send invitation:', response);
    }
  };

  const handleCancelInvitation = async (username) => {
    const url = '/tt_invitation_cancel_searchable';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const response = await api.post(url, { invitee_username: username }, { headers }, token);
    if (response.ok) {
      fetchSearchableUsers();
    } else {
      console.error('Failed to cancel invitation:', response);
    }
  };

  if (!user || !user.data) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" size="sm" />
      </div>
    );
  }

  const handleCloseModal = () => setShowUpgradeModal(false);

  return (
    <Body>
      <div className="text-center mb-4">
        <Button
          onClick={toggleSearchableStatus}
          variant={user.data.user_searchable ? 'outline-primary' : 'outline-danger'}
        >
          {user.data.user_searchable ? 'Hide Your Profile from Tiki Tribe Search' : 'Make Your Profile Searchable in Tiki Tribe'}
        </Button>
      </div>

      <div className="mb-5">
        <h3 className="text-center">Sent Invitations</h3>
        {sentInvitations.length > 0 ? (
          <ul className="list-unstyled">
            {sentInvitations.map((invitation) => (
              <li key={`sent-${invitation.username}`} className="mb-3">
                <Card className="shadow-sm p-3 rounded">
                  <Stack gap={1} className="align-items-center flex-column flex-sm-row">
                    <Image
                      src={invitation.avatar || '/tt_icon.png'}
                      onError={(e) => { e.target.src = '/tt_icon.png'; }}
                      alt="Avatar"
                      roundedCircle
                      style={{ width: '48px', height: '48px', objectFit: 'cover' }}
                    />
                    <div className="flex-grow-1 text-center text-sm-left">
                      <span className="font-weight-bold">{invitation.username}</span>
                    </div>
                    <div className="w-100 d-sm-none" />
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => handleCancelInvitation(invitation.username)}
                      className="mt-2 mt-sm-0"
                    >
                      Cancel Invitation
                    </Button>
                  </Stack>
                </Card>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-center">No invitations sent.</p>
        )}
      </div>

      <div className="mb-5">
        <h3 className="text-center">Searchable Users</h3>
        {searchableUsers.length > 0 ? (
          <ul className="list-unstyled">
            {searchableUsers.map((searchableUser) => (
              <li key={`searchable-${searchableUser.username}`} className="mb-3">
                <Card className="shadow-sm p-3 rounded">
                  <Stack gap={1} className="align-items-center flex-column flex-sm-row">
                    <Image
                      src={searchableUser.avatar || '/tt_icon.png'}
                      onError={(e) => { e.target.src = '/tt_icon.png'; }}
                      alt="Avatar"
                      roundedCircle
                      style={{ width: '48px', height: '48px', objectFit: 'cover' }}
                    />
                    <div className="flex-grow-1 text-center text-sm-left">
                      <span className="font-weight-bold">{searchableUser.username}</span>
                    </div>
                    <div className="w-100 d-sm-none" />
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => handleSendInvitation(searchableUser.username)}
                      className="mt-2 mt-sm-0"
                    >
                      Send Invitation
                    </Button>
                  </Stack>
                </Card>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-center">No searchable users available.</p>
        )}
      </div>

      <UpgradeModal show={showUpgradeModal} handleClose={handleCloseModal} />
    </Body>
  );
};

export default FriendsSearchPage;
