import React from 'react';
import Form from 'react-bootstrap/Form';

export default function InputField({
                                     name,
                                     label,
                                     type,
                                     placeholder,
                                     error,
                                     value,
                                     onChange,
                                     as, // Add this prop to differentiate between 'input' and 'textarea'
                                     rows, // Pass the rows prop for the textarea
                                     ref, // Pass ref for the textarea
                                     onFocus, // onFocus event handler
                                     onBlur, // onBlur event handler
                                     onKeyDown // onKeyDown event handler
                                   }) {
  return (
    <Form.Group controlId={name} className="InputField">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        as={as} // Allow 'textarea' or 'input'
        type={type || 'text'} // Default to 'text' for inputs
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows={rows} // Set initial rows for textarea
        ref={ref} // Ref for direct access to textarea
        onFocus={onFocus} // Event handler for focus
        onBlur={onBlur} // Event handler for blur
        onKeyDown={onKeyDown} // Event handler for key down
        style={{
          resize: 'none', // Prevent manual resizing
          overflow: 'hidden' // Hide overflow to ensure smooth height adjustment
        }}
      />
      <Form.Text className="text-danger">{error}</Form.Text>
    </Form.Group>
  );
}
