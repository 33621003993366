import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Post from "../components/Post";
import Comment from "../components/Comment";


function Comments({ comment, opID, updateCommentInParent, removeCommentFromParent, addCommentToParent, level = 1 }) {
  const indent = 20 * level; // Adjust indentation level for nested comments
  return (
    <div style={{ marginLeft: `${indent}px`, marginTop: '10px' }}>
      <Comment
        key={comment.PostID}
        comment={comment}
        opID={opID}
        updateCommentInParent={updateCommentInParent}
        removeCommentFromParent={removeCommentFromParent}
        addCommentToParent={addCommentToParent}
      />
      {comment.Comments && comment.Comments.map(reply => (
        <Comments
          key={reply.PostID}
          comment={reply}
          opID={opID}
          updateCommentInParent={updateCommentInParent}
          removeCommentFromParent={removeCommentFromParent}
          addCommentToParent={addCommentToParent}
          level={level + 1} // Increase the level for nested comments
        />
      ))}
    </div>
  );
}

export default function Posts({ post, updatePostInParent, removePostFromParent, initialShowComments = true }) {
  // Use the initialShowComments prop to set the initial state
  const [showComments, setShowComments] = useState(initialShowComments);

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const updateCommentInParent = (updatedComment) => {
    post.Comments = post.Comments.map(comment =>
      comment.PostID === updatedComment.PostID ? updatedComment : comment
    );
    updatePostInParent(post);
  };

  const removeCommentFromParent = (commentID) => {
    post.Comments = post.Comments.filter(comment => comment.PostID !== commentID);
    updatePostInParent(post);
  };

  const addCommentToParent = (newComment) => {
    // // Only add the comment if not in PWA mode
    // if (!isPWA()) {
    //   post.Comments = [...post.Comments, newComment];
    //   updatePostInParent(post);
    // }
    post.Comments = [...post.Comments, newComment];
    updatePostInParent(post);
  };

  return (
    <div style={{
      marginBottom: '20px',
      border: '1px solid #e2e2e2',
      borderRadius: '8px',
      backgroundColor: '#f0f4ff', // Slightly different background color for posts
      padding: '15px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
      maxWidth: '800px', // Limit the width for better readability on larger screens
      marginLeft: 'auto', // Center the container
      marginRight: 'auto'
    }}>
      {/* Post component with the original post content */}
      <Post
        key={post.PostID}
        post={post}
        updatePostInParent={updatePostInParent}
        removePostFromParent={removePostFromParent}
      />

      {/* Button to show/hide comments */}
      {post.Comments && post.Comments.length > 0 && (
        <div style={{ marginTop: '10px', textAlign: 'right' }}>
          <Button variant="link" onClick={toggleComments} style={{ padding: '0', textDecoration: 'underline', color: '#007bff' }}>
            {showComments ? 'Hide Comments' : 'Show Comments'}
          </Button>
        </div>
      )}

      {/* Comments Section */}
      {showComments && post.Comments && post.Comments.length > 0 && (
        <div style={{ marginTop: '10px' }}>
          {post.Comments.map(comment => (
            <Comments
              key={comment.PostID}
              comment={comment}
              opID={post.PostID}
              updateCommentInParent={updateCommentInParent}
              removeCommentFromParent={removeCommentFromParent}
              addCommentToParent={addCommentToParent}
            />
          ))}
        </div>
      )}
    </div>
  );
}
