import React, { useState, useRef } from 'react';
import { useAuth } from "../contexts/AuthContext";
import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from 'react-bootstrap/Form'; // Import Form for text input styling
import { useUser } from "../contexts/UserProvider";
import { useApi } from '../contexts/ApiProvider';
import minusIcon from '../assets/ps_minus.png';

// // Utility function to check if running in PWA mode
// const isPWA = () => window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

function FileUploadAndPost({ toggleInputType, showPost, initialText }) {
  const { user } = useUser();
  const api = useApi();
  const { token, username } = useAuth();
  const [file, setFile] = useState(null);
  const [content, setContent] = useState(initialText || '');
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const handleUploadAndPost = async () => {
    // Check if there is any text content
    if (!file && !content.trim()) {
      alert('Please enter some text or select a file.');
      return;
    }

    setLoading(true);

    try {
      // If there is no file, send text-only content
      if (!file) {
        const postData = { text: content };
        const response = await api.post('/tt_posts_post', postData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }, token);

        if (response.ok) {
          const responseData = response.body;
          // // Only add post to showPost if not in PWA mode
          // if (!isPWA()) {
          //   showPost(responseData);
          // }
          showPost(responseData);
          setContent('');
          toggleInputType(); // Switch back to Write.js component
        } else {
          console.error('Error submitting text-only post:', response);
        }
      } else {
        // If there is a file, proceed with the file upload
        const timestamp = Date.now();
        const fileKey = `${username}-${timestamp}-${file.name}`;

        const presignedUrlResponse = await api.get('/tt_s3_url_get', {
          file_name: encodeURIComponent(fileKey)
        }, { headers: { 'Authorization': `Bearer ${token}` } }, token);

        if (!presignedUrlResponse.ok) {
          alert('Failed to get the pre-signed URL.');
          setLoading(false);
          return;
        }

        const { upload_url, original_object_name } = presignedUrlResponse.body;

        const uploadResult = await fetch(upload_url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        });

        if (!uploadResult.ok) {
          alert('Failed to upload the file.');
          setLoading(false);
          return;
        }

        const postData = {
          text: content,
          original_object_name: original_object_name
        };

        const response = await api.post('/tt_posts_post', postData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }, token);

        if (response.ok) {
          const responseData = response.body;
          // // Only add post to showPost if not in PWA mode
          // if (!isPWA()) {
          //   showPost(responseData);
          // }
          showPost(responseData);
          // toggleInputType(); // Switch back to Write.js component
          setFile(null);
          setContent('');
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          toggleInputType();
        } else {
          const errorData = response.body;
          if (errorData.errors) {
            console.log('Errors');
          }
        }
      }
    } catch (error) {
      console.error('Error during upload:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!user || !user.data) {
    return <div>Loading...</div>;
  }

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div style={{
      padding: '15px',
      marginBottom: '20px',
      backgroundColor: '#ffffff',
      border: '1px solid #e2e2e2',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    }}>
      <Stack direction="horizontal" gap={3} className="Write" style={{ alignItems: 'center' }}>
        <Image
          src={user.data.avatar || '/tt_icon.png'}
          onError={(e) => { e.target.src = '/tt_icon.png'; }}
          roundedCircle
          style={{
            width: '48px',
            height: '48px',
            objectFit: 'cover',
            border: '2px solid #e2e2e2',
            marginRight: '12px'
          }}
        />
        <div style={{ flexGrow: 1 }}>
          <Button variant="outline-primary" onClick={triggerFileInput} style={{ marginBottom: '10px', width: '100%' }}>
            Choose File
          </Button>
          <input
            type="file"
            id="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          {file && (
            <p style={{ margin: '5px 0', fontSize: '14px', color: '#6c757d' }}>
              Selected file: {file.name}
            </p>
          )}
          <Form.Group controlId="postContent" style={{ marginTop: '10px' }}>
            <Form.Control
              as="textarea"
              rows={2}
              value={content}
              onChange={handleContentChange}
              placeholder="What's the latest?"
              style={{
                borderRadius: '8px',
                border: '1px solid #ced4da',
                padding: '10px',
                fontSize: '16px',
                width: '100%',
              }}
            />
          </Form.Group>
          <Button
            onClick={handleUploadAndPost}
            variant="outline-success"
            disabled={loading}
            style={{ width: '100%'}}
          >
            {loading ? <Spinner animation="border" size="sm" /> : 'Upload & Post'}
          </Button>
        </div>
        <Image
          src={minusIcon}
          alt={'Switch back to text input'}
          roundedCircle
          style={{
            width: '40px',
            height: 'auto',
            cursor: 'pointer'
          }}
          onClick={toggleInputType}
        />
      </Stack>
    </div>
  );
}

export default FileUploadAndPost;
